import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Axios from '../../services/AxiosInstance';
import Loader from '../common/Loader';

const GemStateChapters = () => {
  const [personsList, setPersonsList] = useState([]);
  const [searchParam] = useSearchParams();
  const [stateName, setStateName] = useState('');
  const Navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);


  const getStatePersons = () => {
    Axios.get(`api/assocham/get-gem-state-chapter-persons?state_name=${searchParam.get('state_name')}`, setIsLoading(true))
      .then((res) => {
        console.log(res);
        setPersonsList(res.data.data);
        setStateName(res.data.data[0].state_name);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      })
  }
  useEffect(() => {
    if (!searchParam.get('state_name')) {
      Navigate('/')
    }
    getStatePersons();
  }, [])

  const showPersons = personsList.map((item, index) => {
    return (
      <>
        <div className="col-md-3" key={index}>
          <div className="gemchapter-cont">
            <img src={process.env.REACT_APP_TEST_IMAGE_BASE_URL + item.chairman_image} />
            <h2>
              {item.chairman_name}
            </h2>
            <p>
              {item.chairman_designation}
            </p>
          </div>
        </div>
        <div className="col-md-3">
          <div className="gemchapter-cont">
            <img src={process.env.REACT_APP_TEST_IMAGE_BASE_URL + item.co_chairman_image} />
            <h2>
              {item.co_chairman_name}
            </h2>
            <p>
              {item.co_chairman_designation}
            </p>
          </div>
        </div>
      </>

    )
  })

  if (isLoading) {
    return (
      <div className="container-fluid">
        <div className="row">
          <Loader />
        </div>
      </div>
    )
  }



  return (
    <div>
      <div className="abt-bnr-sec">
        <div className="abt-hding">
          <h2>GEM {stateName} Chapter</h2>
        </div>
        <div className="bread-crb">
          <ul>
            <li><Link to=''>Home</Link></li><span><i className="flaticon-next"></i></span>
            <li><Link to=''>GEM {stateName} Chapter</Link></li>
          </ul>
        </div>
      </div>

      <div className="gemchapterrow">
        <div className="container">
          <div className="row">
            {showPersons}
          </div>
        </div>

      </div>

    </div>

  )
}

export default GemStateChapters;