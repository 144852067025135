import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/home/Home';
import Header from './components/layout/header/Header';
import Footer from './components/layout/footer/Footer';
import AboutUs from './components/about/AboutUs';
import AssochamSustainabillity from './components/assochamSustainability/AssochamSustainability';
import GemCertificationSystem from './components/gemCertificationSystem/GemCertificationSystem';
import Environmentalassesment from './components/environmentalassesment/EnvironmentalAssesment';
import Gemleadership from './components/gemleadership/Gem-Leadership';
import Event from './components/event/Event';
import Resources from './components/resources/Resources';
import EventTwo from './components/event/EventTwo';
import Registration from './components/registration/Registration';
import GemCp from './components/gemcp/GemCp';
import ContactUs from './components/contactus/ContactUs';
import AssochamGemChapter from './components/assochamGemChapter/AssochamGemChapter';
import QuestionList from './components/mcq/QuestionList';
import Instructions from './components/mcq/instructions';
import Preview from './components/mcq/Preview';
import Payment from './components/payment/Payment';
import PaymentForm from './components/paymentForm/PaymentForm';
import AssochamGemEcoProducts from './components/assochamGemChapter/AssochamGemEcoProducts';
import AssochamGreenBuilding from './components/assochamGemChapter/AssochamGreenBuilding';
import GemStateChapters from './components/getstatechapter/gemStateChapters';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/aboutus' element={<AboutUs />} />
          <Route path='/assochamsustainabillity' element={<AssochamSustainabillity />} />
          <Route path='/gemCertificationSystem' element={<GemCertificationSystem />} />
          <Route path='/AssochamSustainabillity' element={<Environmentalassesment />} />
          <Route path='/gemleadership' element={<Gemleadership />} />
          <Route path='/event' element={<Event />} />
          <Route path='/eventTwo' element={<EventTwo />} />
          <Route path='/resources' element={<Resources />} />
          <Route path='/registration' element={<Registration />} />
          <Route path='/gemcp' element={<GemCp />} />
          <Route path='/contactus' element={<ContactUs />} />
          <Route path='/assochamGemChapter' element={<AssochamGemChapter />} />
          <Route path='/mcq' element={<QuestionList />} />
          <Route path='/Instructions' element={<Instructions />} />
          {/* <Route path='/submit' element={<Submit />} /> */}
          <Route path='/preview' element={<Preview />} />
          <Route path='/Payment' element={<Payment />} />
          <Route path='/paymentform' element={<PaymentForm />} />
          <Route path='/gemStateChapters' element={<GemStateChapters />} />

          <Route path='/assocham-gem-eco-products' element={<AssochamGemEcoProducts />} />
          <Route path='/assocham-assocham-Green-building' element={<AssochamGreenBuilding/>}/>
        </Routes>
        <Footer />
      </BrowserRouter>

    </div>
  );
}

export default App;
