import axios from "axios";
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Alter defaults after instance has been created
//  instance.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

// Add a request interceptor
instance.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
)

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, async function (error) {
  console.log(error);

  return Promise.reject(error);
});

export default instance; 