import React from 'react';
import { Link } from 'react-router-dom';
import ftrlogo3 from '../../../assets/img/ftr-logo3.png';

const Footer = () => {
    return (
        <>
            <div className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-lg-4 md-mb-30">
                            <div className="footer-widget">

                                <img src={ftrlogo3} alt="" />
                                <div className="widget about-info">
                                    <p>THE ASSOCIATED CHAMBERS OF COMMERCE & INDUSTRY OF INDIA</p>
                                </div>
                                {/* <!-- <div className="widget social-icon">
              <ul className="footer-social">
                <li>
                  <a href="#"><i className="flaticon-facebook-app-symbol"></i></a>
                </li>
                <li>
                  <a href="#"><i className="flaticon-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i className="flaticon-instagram"></i></a>
                </li>
                <li>
                  <a href="#"><i className="flaticon-vimeo"></i></a>
                </li>
              </ul>
            </div> --> */}
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 sm-mt-30 sm-mb-30 m-box">
                            <div className="footer-widget">
                                <h3>Quick Links</h3>
                                <div className="widget footer-menu">
                                    <ul>
                                        <li>
                                            <Link to="/index">
                                                <i className="flaticon-double-right-arrows-angles"></i>
                                                About ASSOCHAM
                                            </Link>
                                        </li>
                                        <li>
                                           <Link><i className="flaticon-double-right-arrows-angles"></i>
                                                About GEM Rating</Link>
                                            
                                        </li>
                                        <li>
                                            <Link><i className="flaticon-double-right-arrows-angles"></i>
                                                GEM Rating Systems</Link>
                                           
                                        </li>
                                        <li>
                                            <Link><i className="flaticon-double-right-arrows-angles"></i>
                                                Project Registration</Link>
                                           
                                        </li>
                                        <li>
                                            <Link to='/Payment'><i className="flaticon-double-right-arrows-angles"></i>Payment
                                                </Link>
                                           
                                        </li>
                                        {/* <!-- <li>
                  <a href="team.html"
                    ><i className="flaticon-double-right-arrows-angles"></i>CGEM
                    Officials</a
                  >
                </li> --> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 m-box">
                            <div className="footer-widget">
                                <h3>Contact Us</h3>
                                <div className="widget basic-info">
                                    <ul>
                                        <li>
                                            <i className="flaticon-placeholder"></i>4th Floor, YMCA Cultural
                                            Centre and Library Building,01, Jai Singh Road, New Delhi -
                                            110001
                                        </li>
                                        <li><i className="flaticon-call"></i>+91 9315700465</li>
                                        <li>
                                            <i className="flaticon-envelope"></i>amitkumar.sharma@assocham.com
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="copyright-content">
                                <p>© 2018-2022. All rights reserved</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="txt-rgt">
                                <a
                                    href="/#"
                                    style={{ color: "#000" }}
                                >Terms &amp; Conditions
                                </a>
                                <span>|</span>
                                <a
                                    href="/#"
                                    style={{ color: "#000" }}
                                >Privacy Policy</a>
                                <span>|</span>
                                <a
                                    href="/#"
                                    style={{ color: "#000" }}
                                >Videos</a>
                                <span>|</span><a
                                    href="/#"
                                    style={{ color: "#000" }}
                                >PAYMENT PAGE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Footer;