import sliderImg3 from '../../assets/img/slider-img3.jpg';
import sliderImg4 from '../../assets/img/slider-img4.jpg';
import sliderImg2 from '../../assets/img/slider-img2.jpg';
import sliderImg5 from '../../assets/img/slider-img-new1.jpg';
import sliderImg6 from '../../assets/img/slider-img-new2.jpg';
import sliderImg7 from '../../assets/img/slider-img-new3.jpg';
import sliderImg8 from '../../assets/img/slider-img8.jpeg';
const people = [
    {
        id: 1,
        image: sliderImg3,
        name: 'maria ferguson',
        title: 'office manager',
        quote:
            'Fingerstache umami squid, kinfolk subway tile selvage tumblr man braid viral kombucha gentrify fanny pack raclette pok pok mustache.',
    },
    {
        id: 2,
        image: sliderImg4,
        name: 'john doe',
        title: 'regular guy',
        quote:
            'Gastropub sustainable tousled prism occupy. Viral XOXO roof party brunch actually, chambray listicle microdosing put a bird on it paleo subway tile squid umami.',
    },
    {
        id: 3,
        image: sliderImg2,
        name: 'peter smith',
        title: 'product designer',
        quote:
            'Drinking vinegar polaroid street art echo park, actually semiotics next level butcher master cleanse hammock flexitarian ethical paleo.',
    },
    {
        id: 4,
        image: sliderImg5,
        name: 'peter smith',
        title: 'product designer',
        quote:
            'Drinking vinegar polaroid street art echo park, actually semiotics next level butcher master cleanse hammock flexitarian ethical paleo.',
    },
    {
        id: 5,
        image: sliderImg6,
        name: 'peter smith',
        title: 'product designer',
        quote:
            'Drinking vinegar polaroid street art echo park, actually semiotics next level butcher master cleanse hammock flexitarian ethical paleo.',
    },
    {
        id: 6,
        image: sliderImg7,
        name: 'peter smith',
        title: 'product designer',
        quote:
            'Drinking vinegar polaroid street art echo park, actually semiotics next level butcher master cleanse hammock flexitarian ethical paleo.',
    },
    {
        id: 7,
        image: sliderImg8,
        name: 'peter smith',
        title: 'product designer',
        quote:
            'Drinking vinegar polaroid street art echo park, actually semiotics next level butcher master cleanse hammock flexitarian ethical paleo.',
    },
];

export default people;