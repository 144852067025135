import React, { useEffect, useState } from "react";
import './resources.css';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import RenderHtml from '../common/htmlRender';
import 'react-tabs/style/react-tabs.css';
import Axios from '../../services/AxiosInstance';
import { Modal, Button } from "react-bootstrap";
import Loader from "../common/Loader";
const Resources = () => {
    const [searchParams] = useSearchParams();
    const [tabIndex, setTabIndex] = useState(0);
    const [importantLinks, setImportantLinks] = useState([]);
    const [importantLinkHeading, setImportantLinkHeading] = useState('');
    const [importantLinkContent, setImortantLinkContent] = useState('');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile_no, setMobileNo] = useState('');
    const [state, setState] = useState('');
    const [isPopUpOpen, setIsPopUpOpen] = useState(false);


    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isEmailIsSended, setIsEmailIsSended] = useState(false);

    const [isLoading, setIsLoading] = useState({ isImportantLinkLoader: false, isLinkContentLoader: false });

    const Navigate = useNavigate();

    const getAllImportantLink = () => {
        Axios.get(`api/assocham/all-important-links?menu_id=${searchParams.get('menu_id')}&sub_menu_id=${searchParams.get('sub_menu_id')}`, setIsLoading({ ...isLoading, isImportantLinkLoader: true }))
            .then((res) => {
                if (res.status === 200) {
                    setImportantLinks(res.data.data);
                    handleImportantContent(res.data.data[0].content_menu_id);
                    setImportantLinkHeading(res.data.data[0].content_menu_name);
                    setIsLoading({ ...isLoading, isImportantLinkLoader: false });
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading({ ...isLoading, isImportantLinkLoader: false });
            })
    }

    const showImportLinkData = importantLinks.map((link, index) => {
        return (
            <li key={index} onClick={() => handleImportantContent(link.content_menu_id)} className={importantLinkHeading == link.content_menu_name ? 'Active' : ''}>{link.content_menu_name}</li>
        )
    })

    const handleImportantContent = (important_link_id) => {
        console.log(important_link_id);
        for (let i = 0; i < importantLinks.length; i++) {
            if (important_link_id == importantLinks[i].content_menu_id) {
                setImportantLinkHeading(importantLinks[i].content_menu_name);
            }
        }
        Axios.get(`api/assocham/get-important-links-data?content_menu_id=${important_link_id}`, setIsLoading({ ...isLoading, isLinkContentLoader: true }))
            .then((res) => {
                // console.log("data", res);
                if (res.status === 200) {
                    setImortantLinkContent(res.data.data[0].content);
                    setIsLoading({ ...isLoading, isLinkContentLoader: false });
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading({ ...isLoading, isLinkContentLoader: false });

            })
    }

    useEffect(() => {
        if (!searchParams.get('menu_id')) {
            Navigate('/');
        }
        getAllImportantLink();
    }, [])


    const HandleOpenPopup = () => {
        setIsPopUpOpen(true);
    }

    const handleSubmit = () => {
        if (!name) {
            setErrorMessage("Please Enter Name!");
            setIsError(true);
            return;
        }
        if (!email) {
            setErrorMessage("Please Enter email!");
            setIsError(true);
            return;
        }
        if (!mobile_no) {
            setErrorMessage("Please Enter Mobile No!");
            setIsError(true);
            return;
        }
        if (!state) {
            setErrorMessage("Please Enter Location!");
            setIsError(true);
            return;
        }
        Axios.post(`api/assocham/registered-and-sendemail`, {
            name: name,
            email: email,
            mobile_no: mobile_no,
            state: state
        })
            .then((res) => {
                console.log(res.status);
                if (res.status === 200) {
                    setIsEmailIsSended(true);
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <>
            <div className="abt-bnr-sec">
                <div className="abt-hding">
                    <h2>Resources</h2>
                </div>
                <div className="bread-crb">
                    <ul>
                        <li><Link to=''>Home</Link></li><span><i className="flaticon-next"></i></span>
                        <li><Link to=''>Resources</Link></li>
                    </ul>
                </div>
            </div>


            <div className="abt-content-sec">
                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <div className="container">

                        <div className="row">
                            <div className="col-md-4">
                                <div className="important_Links">
                                    <h2>Important Links</h2>
                                    <ul>
                                        {isLoading.isImportantLinkLoader ? <Loader /> : ""}
                                        {showImportLinkData}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-8 resource">
                                <div className="right-cont">
                                    <h2>{importantLinkHeading}</h2>
                                </div>
                                <div className="right-txt"><RenderHtml htmlString={importantLinkContent} /> {isLoading.isLinkContentLoader ? <Loader /> : ""}</div>
                                {
                                    importantLinkHeading == "Reference" ?
                                        <button onClick={HandleOpenPopup}>Register for download 'Resource Free Guidance'</button>
                                        : ''
                                }
                            </div>
                        </div>
                    </div>
                </Tabs>
            </div>
            <Modal data-bs-backdrop="static" show={isPopUpOpen} className="thnku_pop refrnce-pop">
                <Modal.Header>
                    {isError ? <span className="error">{errorMessage}</span> : ""}
                    {isEmailIsSended ? <span className="success">Email Is Sended At your Email Id</span> : ""}
                </Modal.Header>
                <Modal.Body>
                    <div class="refrnce-frm">
                        <label>Name</label>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                        <label>Email</label>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <label>Mobile No</label>
                        <input type="text" value={mobile_no} onChange={(e) => setMobileNo(e.target.value)}/>
                        <label>Location</label>
                        <input type="text" value={state} onChange={(e) => setState(e.target.value)}/>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="ft-btn">
                        <Button variant="secondary" onClick={() => setIsPopUpOpen(false)}>
                            close
                        </Button>
                        <Button variant="light" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default Resources;
