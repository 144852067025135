import React from 'react';

const RenderHtml = (props) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(props.htmlString, 'text/html');

    // Update the src attributes of all image tags in the HTML
    const images = doc.querySelectorAll('img');
    images.forEach(img => {
        const src = img.getAttribute('src');
        img.setAttribute('src', `${process.env.REACT_APP_TEST_IMAGE_BASE_URL}${src}`);
    });

    const serializedHTML = new XMLSerializer().serializeToString(doc.documentElement);
    return <div dangerouslySetInnerHTML={{ __html: serializedHTML }} />;
}

export default RenderHtml;
// Usage:
