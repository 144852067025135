import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import image1 from '../../../assets/img/brks.png';
import image2 from '../../../assets/img/logo-img2.png';
import image3 from '../../../assets/img/logo-img1.png';
import '../../../assets/fonts/flaticon.css';
import './style.css';
import axios from "axios";
// import '../../../assets/js/custom/';
import Helmet from 'react-helmet'


const Header = () => {
    const [menuListData, setMenuListData] = useState([]);
    const getMenuList = async () => {
        axios.get("http://localhost:3500/web/api/get-menu-list")
            .then((res) => {
                console.log(res);
                setMenuListData(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }
    useEffect(() => {
        getMenuList();
    }, [])




    const menuList = menuListData.map((menu, index) => {

        return (
            <>


                <li className="nav-item dropdown" id="myDropdown">
                    <Link to={menu.menu_link}>{menu.menu_name}</Link>
                    {
                        menu.sub_menu ?
                            <ul>
                                <li>Hello</li>
                                {/* {menuListData.sub_menu.map((list) => {
                            return (
                                <li>
                                    <Link to="">{menu.menu_name}</Link>
                                </li>
                            )
                        })} */}
                            </ul> :
                            ""

                    }

                </li>
            </>
        )
    })
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);


    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });


    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
        const header = document.querySelector('.header-menu');
        const scrollTop = window.scrollY;
        scrollTop >= 20 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };



    const [activeItem, setActiveItem] = useState(null);

    const handleItemClick = (index) => {
        setActiveItem(index);
    };




    return (
        <>

            <div className="top-bar">
                {loading ? (
                    <div className="loader-container">
                        <div className="spinner">
                            <img src={image3} alt="" />
                        </div>
                    </div>
                ) : (


                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <div className="top-left">
                                    {/* <img src={image1} alt="" /> */}
                                    {/* <p>Welcome To Green ASSOCHAM </p> */}
                                    <Link to='/assochamsustainabillity'> <marquee>Welcome to ASSOCHAM Sustainability Initiatives</marquee></Link>

                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <div className="top-right d-i-block text-right">
                                    <ul>
                                        <li>
                                            <a href="#"><i class="flaticon-facebook-app-symbol"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i class="flaticon-twitter"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i class="flaticon-linkedin"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i class="flaticon-vimeo"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i class="flaticon-instagram"></i></a>
                                        </li>
                                        <li className="cont-btn"><Link to='/contactus'>Contact Us</Link></li>

                                    </ul>

                                </div>

                            </div>

                        </div>
                    </div>
                )}

            </div>
            <div className="header-menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-8">
                            <div className="menu-logo">
                                <ul className={activeItem === 0 ? 'active' : ''} onClick={() => handleItemClick(0)}>
                                    <li className="logo-img2">
                                        <Link to=''>
                                            <img src={image2} alt="" />
                                        </Link></li>
                                    <li className="logo-img1">
                                        <Link to=''>
                                            <img src={image3} alt="" />
                                        </Link>
                                    </li>

                                </ul>


                            </div>

                        </div>

                        <div className="col-xl-9 col-lg-9 col-md-6 col-sm-4 menu-pd">
                            <button class="navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="toggler-icon top-bar"></span>
                                <span class="toggler-icon middle-bar"></span>
                                <span class="toggler-icon bottom-bar"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarNav">
                                <div className="main-menu navbar-nav">
                                    <ul id="mobilemenu">
                                        <li className={activeItem === 0 ? 'active' : ''} onClick={() => handleItemClick(0)}><Link to='/aboutus?menu_id=72&sub_menu_id=0' >About Us</Link></li>
                                        <li className={activeItem === 2 ? 'active' : ''} onClick={() => handleItemClick(2)}><Link to='/gemCertificationSystem?menu_id=75&sub_menu_id=0'>GEM Green Certification </Link></li>
                                        <li className={activeItem === 1 ? 'active' : ''} onClick={() => handleItemClick(1)}><Link to='/AssochamSustainabillity?menu_id=74&sub_menu_id=0' >Sustainability Solutions</Link></li>
                                        {/* <li class="nav-item dropdown" id="myDropdown">
                <Link to=''>GEM Rating Systems</Link>
                  <ul>
                    <li><a class="dropdown-item" href="#">GEM Sustainable New Buildings</a></li>
                    <li><a class="dropdown-item" href="#">GEM Sustainable Existing Buildings</a></li>
                    <li><a class="dropdown-item" href="#">GEM Sustainable Homes</a></li>
                    <li><a class="dropdown-item" href="#">GEM Sustainable Factory Buildings</a></li>
                    <li><a class="dropdown-item" href="#">GEM Sustainable Warehouse and Logistics Hub</a></li>
                    <li><a class="dropdown-item" href="#">GEM Sustainable Healthcare Buildings</a></li>
                    <li><a class="dropdown-item" href="#">GEM Sustainable Green Interiors</a></li>
                  </ul>
                
                </li> */}
                                        {/* <li class="nav-item dropdown" id="myDropdown"><a href="#">Project Registration</a>
                    <ul>
                        <li><a href="#">Create a new account</a></li>
                        <li><a href="#">Project Sign In</a></li>
                        <li><a href="#">GEM Certification Fees</a></li>
                    </ul>
                </li> */}

                                        <li className={activeItem === 4 ? 'active' : ''} onClick={() => handleItemClick(4)}><Link to='/assochamGemChapter'>GEM Ecosystem </Link>
                                            <ul className="sub-menu">
                                                <li><Link to="/assochamGemChapter">GEM Chapters</Link></li>
                                                <li><Link to="/assocham-gem-eco-products?menu_id=76&sub_menu_id=110">GEM Eco-Products</Link></li>
                                                <li><Link to="/assocham-assocham-Green-building?menu_id=76&sub_menu_id=111">GEM Green Building Certification</Link></li>
                                                <li><a href="#">GEM CPs</a></li>

                                            </ul>
                                        </li>

                                        <li className={activeItem === 5 ? 'active' : ''} onClick={() => handleItemClick(5)}><Link to='/gemcp?menu_id=77&sub_menu_id=0'>Certified Professionals </Link></li>
                                        <li className={activeItem === 6 ? 'active' : ''} onClick={() => handleItemClick(6)}><Link to='/event'>Events </Link></li>
                                        <li className={activeItem === 7 ? 'active' : ''} onClick={() => handleItemClick(7)}><Link to='/registration'>Register For Projects </Link></li>
                                        <li className={activeItem === 8 ? 'active' : ''} onClick={() => handleItemClick(8)}><Link to='/resources?menu_id=80&sub_menu_id=0'>Resources </Link></li>


                                        {/* <li class="nav-item dropdown" id="myDropdown"><a href="#">CGEM Officials</a>
                    <ul>
                        <li><a href="#">GEM Executive Committee</a></li>
                        <li><a href="#">GEM State Chapters</a></li>
                        <li><a href="#">GEM Committees</a></li>
                    </ul>
                </li> */}
                                        {/* <li><a href="#">Events</a></li>
                <li><a href="#">Payment</a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </>

    )
}

export default Header;