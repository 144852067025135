import React from "react";
import './gem-leadership.css';
import { Link } from "react-router-dom";
import leaderimg1 from '../../assets/img/Mr__Pankaj_R_Dharkar.jpeg';
import leaderimg2 from '../../assets/img/Ar__Surinder_Bahga_new.jpg';
import leaderimg3 from '../../assets/img/tushar-sogani.jpg';
import leaderimg4 from '../../assets/img/ar-anand-tatu.jpg';
import leaderimg5 from '../../assets/img/anupam-mittal.jpg';
import leaderimg6 from '../../assets/img/debatosh-sahu.jpg';
import leaderimg7 from '../../assets/img/leena-kumar.jpg';
import leaderimg8 from '../../assets/img/vilas-avachat-vasant.jpg';


const gemleadership = () => {
    return (
        <>
            <div className="gem-certifiat-secs">
                <div className="abt-hding">
                    <h2>GEM Leadership </h2>
                </div>
                <div className="bread-crb">
                    <ul>
                        <li><Link to=''>Home</Link></li><span><i className="flaticon-next"></i></span>
                        <li><Link to=''>GEM Leadership </Link></li>
                    </ul>
                </div>


            </div>

            <div className="gem-certifiat--cont-sec">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-12 col-sm-12">
                            <div className="right-cont gem-leader">
                                <h2>GEM Leadership</h2>
                            </div>
                            
                        </div>
                        <div className="col-md-3">
                            <div className="leadrship-colm">
                            <div className="leader-img">
                                <img src={leaderimg1} alt="" />
                            </div>
                            <div className="leader-cont">
                                <h3>Mr. Pankaj Dharkar</h3>
                                <p>Chairman, National Council for Green and Eco-friendly Movement</p>
                            </div>
                            </div>
                            
                        </div>
                        <div className="col-md-3">
                            <div className="leadrship-colm">
                            <div className="leader-img">
                                <img src={leaderimg2} alt="" />
                            </div>
                            <div className="leader-cont">
                                <h3>Ar. Surinder Bahga</h3>
                                <p>Chairman, GEM Punjab Chapter</p>
                            </div>
                            </div> 
                        </div>
                        <div className="col-md-3">
                            <div className="leadrship-colm">
                            <div className="leader-img">
                                <img src={leaderimg3} alt="" />
                            </div>
                            <div className="leader-cont">
                                <h3>Ar. Tushar Sogani</h3>
                                <p>Chairman, GEM Rajasthan Chapter and Global Collaborations and State Chapters Committee</p>
                            </div>
                            </div> 
                        </div>
                        <div className="col-md-3">
                            <div className="leadrship-colm">
                            <div className="leader-img">
                                <img src={leaderimg4} alt="" />
                            </div>
                            <div className="leader-cont">
                                <h3>Ar. Anand Tatu</h3>
                                <p>Chairman, GEM Gujarat Chapter </p>
                            </div>
                            </div> 
                        </div>
                        <div className="col-md-3">
                            <div className="leadrship-colm">
                            <div className="leader-img">
                                <img src={leaderimg5} alt="" />
                            </div>
                            <div className="leader-cont">
                                <h3>Ar. Anupam Mittal</h3>
                                <p>Chairman, GEM UP Chapter and Government Advocacy Committee</p>
                            </div>
                            </div> 
                        </div>
                        <div className="col-md-3">
                            <div className="leadrship-colm">
                            <div className="leader-img">
                                <img src={leaderimg6} alt="" />
                            </div>
                            <div className="leader-cont">
                                <h3>Ar. Debatosh Sahu</h3>
                                <p>Chairman, GEM West Bengal Chapter</p>
                            </div>
                            </div> 
                        </div>
                        <div className="col-md-3">
                            <div className="leadrship-colm">
                            <div className="leader-img">
                                <img src={leaderimg7} alt="" />
                            </div>
                            <div className="leader-cont">
                                <h3>Ar. Leena Kumar</h3>
                                <p>Chairperson, GEM Karnataka Chapter</p>
                            </div>
                            </div> 
                        </div>
                        <div className="col-md-3">
                            <div className="leadrship-colm">
                            <div className="leader-img">
                                <img src={leaderimg8} alt="" />
                            </div>
                            <div className="leader-cont">
                                <h3>Ar. Vilas Avachat Vasant</h3>
                                <p>Chairman, GEM Maharashtra Chapter</p>
                            </div>
                            </div> 
                        </div>

                    </div>
                </div>
            </div>

          
        </>
    )
}


export default gemleadership;