import React, { useEffect, useState } from "react";
import './style.css';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import leaderimg from '../../assets/img/leader-img.png';
import leaderimg1 from '../../assets/img/deepak-img.jpg';
import leaderimg2 from '../../assets/img/neeraj-img.jpg';
import leaderimg3 from '../../assets/img/pankaj-img.jpg';
import leaderimg4 from '../../assets/img/sumant-img.jpg';
import RenderHtml from '../common/htmlRender';


import 'react-tabs/style/react-tabs.css';

import Axios from '../../services/AxiosInstance';
import Loader from '../common/Loader';

const AboutUs = () => {
    const [searchParams] = useSearchParams();
    const [tabIndex, setTabIndex] = useState(0);
    const [importantLinks, setImportantLinks] = useState([]);
    const [importantLinkHeading, setImportantLinkHeading] = useState('');
    const [importantLinkContent, setImortantLinkContent] = useState('');
    const [isLoading, setIsLoading] = useState({ isImportantLinkLoader: false, isLinkContentLoader: false });

    const Navigate = useNavigate();

    const getAllImportantLink = () => {
        Axios.get(`api/assocham/all-important-links?menu_id=${searchParams.get('menu_id')}&sub_menu_id=${searchParams.get('sub_menu_id')}`, setIsLoading({ ...isLoading, isImportantLinkLoader: true }))
            .then((res) => {
                if (res.status === 200) {
                    setImportantLinks(res.data.data);
                    handleImportantContent(res.data.data[0].content_menu_id);
                    setImportantLinkHeading(res.data.data[0].content_menu_name);
                    setIsLoading({ ...isLoading, isImportantLinkLoader: false });
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading({ ...isLoading, isImportantLinkLoader: false });
            })
    }

    const showImportLinkData = importantLinks.map((link, index) => {
        return (

            <li key={index} onClick={() => handleImportantContent(link.content_menu_id)} className={importantLinkHeading == link.content_menu_name ? 'Active' : ''}>{link.content_menu_name}</li>

        )
    })

    const handleImportantContent = (important_link_id) => {
        for (let i = 0; i < importantLinks.length; i++) {
            if (important_link_id == importantLinks[i].content_menu_id) {
                setImportantLinkHeading(importantLinks[i].content_menu_name);
            }
        }
        Axios.get(`api/assocham/get-important-links-data?content_menu_id=${important_link_id}`, setIsLoading({ ...isLoading, isLinkContentLoader: true }))
            .then((res) => {
                console.log("data", res);
                if (res.status === 200) {
                    setImortantLinkContent(res.data.data[0].content);
                    setIsLoading({ ...isLoading, isLinkContentLoader: false });
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading({ ...isLoading, isLinkContentLoader: false });
            })
    }

    useEffect(() => {
        if (!searchParams.get('menu_id')) {
            Navigate('/');
        }
        getAllImportantLink();
    }, [])


    return (
        <>
            <div className="abt-bnr-sec">
                <div className="abt-hding">
                    <h2>About Us</h2>
                </div>
                <div className="bread-crb">
                    <ul>
                        <li><Link to=''>Home</Link></li><span><i className="flaticon-next"></i></span>
                        <li><Link to=''>About Us</Link></li>
                    </ul>
                </div>
            </div>

            <div className="abt-content-sec">
                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="important_Links">
                                    <h2>Important Links</h2>
                                    <ul>
                                        {isLoading.isImportantLinkLoader ? <Loader /> : ""}
                                        {showImportLinkData}
                                    </ul>

                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="right-cont">
                                    <h2>{importantLinkHeading}</h2>
                                </div>
                                <div className="right-txt"><RenderHtml htmlString={importantLinkContent} /> {isLoading.isLinkContentLoader ? <Loader /> : ""}</div>
                            </div>
                        </div>
                    </div>
                </Tabs>
            </div>





        </>
    )
}


export default AboutUs;