import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './paymentform.css';
import Axios from '../../services/AxiosInstance';

const PaymentForm = () => {
    const [email, setEmail] = useState({ value: '', errorMessage: '' });
    const [name, setName] = useState({ value: '', errorMessage: '' });
    const [phone, setPhone] = useState({ value: '', errorMessage: '' });
    const [organization, setOrganization] = useState({ value: '', errorMessage: '' });
    const [designation, setDesignation] = useState({ value: '', errorMessage: '' });
    const [gstIn, setGstIn] = useState({ value: '', errorMessage: '' });

    const [isError, setIsError] = useState(false);

    const handlePaymentNow = () => {
        console.log(email, name, phone, organization, designation, gstIn);
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email.value)) {
            setEmail({ ...email, errorMessage: 'Invalid Email' });
        }

        if (!email.errorMessage && !name.errorMessage && !phone.errorMessage && !organization.errorMessage && !designation.errorMessage) {
            // call api
        }
    }
    return (
        <>
            <div className='pymt-section'>
                <div className='pymt-top-sec'>
                    <div className='container'>
                        <div className='row'>

                            <div className='col-md-12'>
                                <div className='pytm-form-hd'>

                                    <h2>THE ASSOCIATED CHAMBERS OF COMMERCE AND INDUSTRY OF INDIA</h2>

                                </div>
                            </div>


                        </div>
                    </div>

                </div>
                <div className='pymt-frm-sec'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-7'>
                                <div className='pymt-frm-cont'>
                                    <h3>ASSOCHAM GEM Certified Professional online Exam</h3>
                                </div>

                                <div className='pymt-form'>
                                    {/* <form action=""> */}

                                    <div className='form-col'>
                                        <label>Email *</label>
                                        <input type="text" name='email' value={email.value} onChange={(e) => setEmail({ value: e.target.value, errorMessage: '' })} />

                                    </div>
                                    <div className='form-col'>
                                        <label>Name *</label>
                                        <input type="text" name='name' value={name.value} onChange={(e) => setName({ value: e.target.value, errorMessage: '' })} />

                                    </div>
                                    <div className='form-col'>
                                        <label>Phone *</label>
                                        <input type="text" name='phone' value={phone.value} onChange={(e) => setPhone({ value: e.target.value, errorMessage: '' })} />

                                    </div>
                                    <div className='form-col'>
                                        <label>Organization *</label>
                                        <input type="text" name='orgaization' value={organization.value} onChange={(e) => setOrganization({ value: e.target.value, errorMessage: '' })} />

                                    </div>
                                    <div className='form-col'>
                                        <label>Designation *</label>
                                        <input type="text" name='designation' value={designation.value} onChange={(e) => setDesignation({ value: e.target.value, errorMessage: '' })} />
                                    </div>
                                    <div className='form-col'>
                                        <label>Gstin</label>
                                        <input type="text" name='gstin' value={gstIn.value} onChange={(e) => setGstIn({ value: e.target.value, errorMessage: '' })} />
                                    </div>
                                    {/* </form> */}

                                </div>

                            </div>

                            <div className='col-md-5'>
                                <div className='pymt-amonut-col'>
                                    <div className='pymt-detail'>
                                        <h3>Payment Details</h3>
                                    </div>
                                    <div className='pymt-amount'>
                                        <p><span>Examination Fees * :</span> Rs 3,500.00</p>
                                        <p><span>GST at 18* :</span> Rs 630.00</p>
                                        <p><span>Total :</span> Rs 4,130.00</p>
                                    </div>

                                    <div className='pymt-term'>
                                        <p>By clicking pay now, you agree to our <Link>terms and conditions</Link></p>
                                    </div>
                                    <div className='pytm-bnt'>
                                        <button onClick={handlePaymentNow}>Pay Now</button>
                                        <button className='pymt-dwload'>Download Pdf</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>



        </>
    )
}

export default PaymentForm