import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import data from './dataPreview.json'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
const Preview = () => {
    const [searchParam] = useSearchParams();
    const Navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);
    const [QuestionPaper, setQuestionPaper] = useState(data.questions);
    useEffect(() => {
        if (searchParam.get('testId') !== 'test1') {
            Navigate('/Instructions')
        }
    })

    const handleSelectOption = (e, selectedOption) => {
        // console.log(e.target.value, selectedOption);
        let selected = "option" + `${selectedOption}`;
        console.log(selected);
        let tempQuestion = QuestionPaper.map((item) => {
            if ((item.questionsId === e.target.value) && (item.selectedOption === selectedOption)) {
                return { ...item, selectedOption: null }
            }
            return item.questionsId === e.target.value ? { ...item, selectedOption: selected } : item
        });
        setQuestionPaper(tempQuestion);
    }
    const questionPaperList = QuestionPaper.map((item, index) => {
        return (
            <>
                <div className="row pre-bdr">
                    <div className="col-md-8">
                        <div className="preview-conts" key={index}>
                            <h4><span>{index + 1}. </span>{item.question}</h4>
                        </div>
                    </div>
                    <div className="col-md-4">

                        <div className="ans-list">
                            {item.selectedOption}
                        </div>
                    </div>
                </div>
            </>
        )
    })
    return (
        <>
            <div className="priview-sec">
                <h2>preview</h2>
                <div className="bread-crb"><ul><li><a href="/">Home</a></li><span><i className="flaticon-next"></i></span><li><a href="/">preview</a></li></ul></div>
            </div>


            <div className="mcq-section">

                <div className="preview-form">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h2>Questions</h2>
                                    </div>
                                    <div className="col-md-4">

                                        <h2>Answers</h2>
                                    </div>
                                </div>
                                {questionPaperList}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Preview;