import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './payment.css';

const Payment = () => {
    const [tabIndex, setTabIndex] = useState(0);
    return (
        <>
            <div className="payment-bnr-sec">
                <div className="abt-hding">
                    <h2>Payment</h2>
                </div>
                <div className="bread-crb">
                    <ul>
                        <li><Link to=''>Home</Link></li><span><i className="flaticon-next"></i></span>
                        <li><Link to=''>Payment</Link></li>
                    </ul>
                </div>


            </div>

            {/* <div className="pymt-content-sec">
                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="important_Links">
                                    <h2>Important Links</h2>
                                    <TabList>
                                        <Tab></Tab>
                                        <Tab>GEM CP</Tab>
                                        <Tab>ASSOCHAM</Tab>
                                        <Tab>Councils on Environment and Climate Change</Tab>
                                        <Tab>Council on Water </Tab>
                                        <Tab>Leadership</Tab>

                                    </TabList>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <TabPanel>

                                </TabPanel>
                                <TabPanel>

                                </TabPanel>

                                <TabPanel>
                                 
                                </TabPanel>
                                <TabPanel>
                                  
                                </TabPanel>
                                <TabPanel>
                                   
                                </TabPanel>
                                <TabPanel>
                                    

                                </TabPanel>

                            </div>
                        </div>
                    </div>
                </Tabs>
            </div> */}

            <div className="pymt-content-sec">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-6">
                            <div className="pymt-col">
                            <div className="pymt-txt">
                                <h2>PAYMENT</h2>
                                <h3>ASSOCHAM GEM Certified Professional online Exam</h3>
                                <h5>Fees Structure</h5>
                                <h5>Examination Fee: Rs. 3,500/- GST @18% = Rs. 630/- Total = Rs. 4,130/-</h5>
                            </div>
                            <div className="pymt-btn">
                                <a href='https://webfront.payu.in/webfront/#/payment/webfrontpage/the-associated-chambers-of-commerce-and-industry-of-india/single/5383'>Pay Now</a>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6">

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Payment;