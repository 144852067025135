import React, { useEffect, useState } from "react";
import './event.css';
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import eventimg1 from '../../assets/img/event-img1.jpg';
import eventimg2 from '../../assets/img/event-img2.jpg';
import eventimg3 from '../../assets/img/event-img3.jpg';
import eventimg4 from '../../assets/img/event-img4.jpg';
import eventimg5 from '../../assets/img/event-img5.jpg';
import eventimg6 from '../../assets/img/event-img6.jpg';

import internationalimg1 from '../../assets/img/international-img1.jpg';
import internationalimg2 from '../../assets/img/international-img2.jpg';
import internationalimg3 from '../../assets/img/international-img3.jpg';
import internationalimg4 from '../../assets/img/international-img4.jpg';
import nationalimg1 from '../../assets/img/nation-img1.jpg';
import nationalimg2 from '../../assets/img/nation-img2.jpg';
import Axios from '../../services/AxiosInstance';
import noida1 from '../../assets/img/noida-1.jpeg';
import noida2 from '../../assets/img/noida-2.jpeg';
import mumbai1 from '../../assets/img/mumbai1.png';
import bhopal1 from '../../assets/img/bhopal-1.png';

import RenderHtml from '../common/htmlRender';
import upcomeEvent1 from '../../assets/img/upcome-event-1.png';

const Event = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [tabIndex2, setTabIndex2] = useState(0);
    const [eventList, setEventList] = useState([]);

    const [eventCategory, setEventCategory] = useState('Upcoming Events');
    const [location, setLocations] = useState('Noida');
    const getEventList = () => {
        Axios.get('api/assocham/event-list')
            .then((res) => {
                console.log(res.data.data);
                setEventList(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }


    useEffect(() => {
        getEventList();
    }, [])

    const showEventList = eventList.map((event, index) => {
        if (eventCategory == event.event_category) {
            return (
                <div className="col-md-6" key={index}>
                    <RenderHtml htmlString={event.event_content} />
                </div>
            )
        }
    })


    const showStateEvent = eventList.map((event, index) => {
        if (eventCategory === "State Level Events" && event.location == location) {
            return (
                <div className="col-md-6" key={index}>
                    <RenderHtml htmlString={event.event_content} />
                </div>
            )
        }
    })

    console.log(eventCategory);
    return (
        <>
            <div className="gem-certifiat-secs">
                <div className="abt-hding">
                    <h2>Event </h2>
                </div>
                <div className="bread-crb">
                    <ul>
                        <li><Link to=''>Home</Link></li><span><i className="flaticon-next"></i></span>
                        <li><Link to=''>Event </Link></li>
                    </ul>
                </div>
            </div>

            <div className="gem-certifiat--cont-sec">

                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="right-cont gem-leader">
                                <h2>Event</h2>
                            </div>
                        </div>
                        <div className="event-names-section">
                            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                                <div className="col-md-12">
                                    <div className="event-name">
                                        <TabList>
                                            <Tab onClick={() => setEventCategory("Upcoming Events")}>Upcoming Events</Tab>
                                            <Tab onClick={() => setEventCategory("International Events")}>International Events</Tab>
                                            <Tab onClick={() => setEventCategory("National Events")}>National Events</Tab>
                                            <Tab onClick={() => setEventCategory("State Level Events")}>St Level Events</Tab>
                                        </TabList>
                                    </div>
                                    <div className="tabs-name-cont">
                                        <TabPanel>
                                            <div className="col-md-6">
                                                <div className="national-colm">

                                                    <div className="national-img">
                                                        {showEventList}
                                                        {/* <img src={upcomeEvent1} alt="" /> */}
                                                    </div>

                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>

                                            <div className="international-cont">
                                                <div className="row">
                                                    {showEventList}
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                                    <div className="event-colm">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-12">
                                                                <div className="event-img">
                                                                    <img src={internationalimg1} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-12">
                                                                <div className="international-cont">
                                                                    <h3>Transitioning Towards Green Economy</h3>
                                                                    <h6><i className="flaticon-date"></i>Wednesday, 9th of November 2022</h6>
                                                                    <p><span>Time :</span>6:45 to 9:00 pm</p>
                                                                    <p><span>Venue :</span>Old Vic Sharm Resort,
                                                                        COP28, Sharm El-Sheikh,
                                                                        Egypt</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="event-colm">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-12">
                                                                <div className="event-img">
                                                                    <img src={internationalimg2} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-12">
                                                                <div className="international-cont">
                                                                    <h3>Inclusive Growth Pathways and
                                                                        Solutions to Integrate Life Principles
                                                                        in Net-Zero Urban Development
                                                                        (Enhancing Energy Efficiency in Micro
                                                                        Small and Medium Enterprises; Hard to
                                                                        Abate Sectors)</h3>
                                                                    <h6><i className="flaticon-date"></i> Friday, 11th of November 2022</h6>
                                                                    <p><span>Time :</span>3:00 to 4:30 pm</p>
                                                                    <p><span>Venue :</span>India Pavilion, Blue Zone, COP27</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-md-6">
                                                    <div className="event-colm">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-12">
                                                                <div className="event-img">
                                                                    <img src={internationalimg3} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-12">
                                                                <div className="international-cont">
                                                                    <h3>LiFE as a Catalyst for Climate Action</h3>
                                                                    <h6><i className="flaticon-date"></i>Monday, 14th November 2022</h6>
                                                                    <p><span>Time :</span>12:30 to 2:00 pm</p>
                                                                    <p><span>Venue :</span>India Pavilion, Blue Zone, COP27</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="event-colm">
                                                        <div className="row">
                                                            <div className="col-lg-4 col-md-12">
                                                                <div className="event-img">
                                                                    <img src={internationalimg4} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-12">
                                                                <div className="international-cont">
                                                                    <h3>Tackling Climate Change: remedial measures for SMEs, sustainable infrastructure, and land governance</h3>
                                                                    <h6><i className="flaticon-date"></i>Wednesday, 16th November 2022</h6>
                                                                    <p><span>Time :</span>1:15 to 2:45 pm</p>
                                                                    <p><span>Venue :</span>Akhenaten, Blue Zone, COP27</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                        </TabPanel>
                                        <TabPanel>
                                            <div className="national-colm">
                                                <div className="row">

                                                    {showEventList}

                                                    {/* <div className="national-img">
                                                            <img src={nationalimg1} alt="" />
                                                        </div>
                                                        <div className="national-cont">
                                                            <h3>GEM 5th International Sustainability Conclave & Expo 2023, Uttar pradesh</h3>
F                                                                    
                                                        </div> */}



                                                </div>
                                                {/*                                                 
                                                <div className="col-md-6">
                                                    <div className="national-colm">
                                                        <div className="national-img">
                                                            <img src={nationalimg2} alt="" />
                                                        </div>
                                                        <div className="national-cont">


                                                            <h3>GEM 4th International Sustainability Conclave & Expo 2023, New Delhi</h3>
                                                         
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </TabPanel>

                                        <TabPanel>
                                            <Tabs selectedIndex={tabIndex2} onSelect={(index) => setTabIndex2(index)}>
                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <div className="important_Links">
                                                            <TabList>
                                                                <Tab onClick={() => setLocations("Noida")}>Noida</Tab>
                                                                <Tab onClick={() => setLocations("Bhopal")}>Bhopal</Tab>
                                                                <Tab onClick={() => setLocations("Mumbai")}>Mumbai</Tab>
                                                            </TabList>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-10 resource">
                                                        <TabPanel>
                                                            <div className="row">
                                                                {showStateEvent}
                                                            </div>

                                                        </TabPanel>

                                                        <TabPanel>
                                                            <div className="row">
                                                                {showStateEvent}
                                                            </div>

                                                        </TabPanel>

                                                        <TabPanel>
                                                            <div className="row">
                                                                {showStateEvent}
                                                            </div>


                                                        </TabPanel>
                                                    </div>
                                                </div>
                                            </Tabs>
                                        </TabPanel>
                                    </div>
                                </div>
                            </Tabs>
                        </div>
                    </div>
                </div>

            </div>


        </>
    )
}


export default Event;