import React from "react";
import './contactus.css';
import { Link } from "react-router-dom";

const ContactUs = () => {
    return (
        <>
            <div className="contact-bnr-sec">
                <div className="abt-hding">
                    <h2>Contact Us</h2>
                </div>
                <div className="bread-crb">
                    <ul>
                        <li><Link to=''>Home</Link></li><span><i className="flaticon-next"></i></span>
                        <li><Link to=''>Contact Us</Link></li>
                    </ul>
                </div>


            </div>

            <div className="contact-content-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div class="single-item">
                            <div class="icon-box"><i class="flaticon-placeholder"></i></div>
                            <h3>Office Location</h3>
                            <p>4th Floor, YMCA Cultural Centre and Library Building,01, Jai Singh Road, New Delhi - 110001</p>
                        </div>
                        </div>
                        <div className="col-md-4">
                        <div class="single-item">
                            <div class="icon-box"><i class="flaticon-envelope"></i></div>
                            <h3>Company Email</h3>
                            <p><a href="mailto:amitkumar.sharma@assocham.com">amitkumar.sharma@assocham.com</a></p>
                           <p> <a href="mailto:example@gmail.com">example@gmail.com</a></p>
                        </div>
                        </div>

                        <div className="col-md-4">
                        <div class="single-item">
                            <div class="icon-box"><i class="flaticon-call"></i></div>
                            <h3>Contact Us</h3>
                            <p><a href="tel:9315700465">+91 9315700465</a></p>
                            <p><a href="tel:9315700465">+91 1230456789</a></p>
                        </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className="contact-style-two">

                <div className="container">
                    <div className="row">

                    <div class="col-lg-8 col-md-12 col-sm-12 big-column offset-lg-2">
                        <div class="form-inner">
                            <h2>Get In Touch</h2>
                            <form method="post" id="contact-form"> 
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                        <input type="text" name="username" placeholder="Your Name" required="" aria-required="true"/>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                        <input type="email" name="email" placeholder="Your email" required="" aria-required="true"/>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                        <input type="text" name="phone" required="" placeholder="Phone" aria-required="true"/>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                                        <input type="text" name="subject" required="" placeholder="Subject" aria-required="true"/>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                                        <textarea name="message" placeholder="Message"></textarea>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 form-group message-btn mr-0 centred">
                                        <button class="theme-btn btn-one" type="submit" name="submit-form">Submit Now</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    </div>
                </div>


            </div>

            <div className="loaction">
                <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.1029403037983!2d77.21088511540218!3d28.626676732420076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1d598c71ccbd%3A0x2f75120924969b5!2sASSOCHAM%20GEM%20Green%20Building%20Council!5e0!3m2!1sen!2sin!4v1672138066595!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>


        </>
    )
}


export default ContactUs;