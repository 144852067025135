
import React, { useState } from "react";
import './registration.css';
import { Link } from "react-router-dom";
import Axios from "../../services/AxiosInstance";


const projectiesData = [
    {
        name: "Select Projects",
        projectlists: [""]
    },
    {
        name: "Green Building Certification",
        projectlists: ["select your project", "New Building", "Existing Building", "Factory Shed", "Hospital", "Educational Institute", "Hotels", "Banks", "Others", "Railway Stations", "Airports", "Ports and Harbours", "Green Interiors"]
    },
    {
        name: "GEM Eco product",
        projectlists: []
    },
    {
        name: "Carbon Footprint",
        projectlists: [""]
    },
    {
        name: "Sustainability Assessment",
        projectlists: [""]
    },

];


const Registration = () => {
    const [isYesChecked, setIsYesChecked] = useState(false);
    const [isNoChecked, setIsNoChecked] = useState(false);


    const [selectedProject, setSelectedProject] = useState('');
    const [selectedSubProject, setSelectedSubProject] = useState('');
    const [salutations, setSalutations] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [countryCode, setCountryCode] = useState('+91');
    const [mobileNum, setMobileNum] = useState('');
    const [TeleNum, setTeleNum] = useState('');
    const [message, setMessage] = useState('');
    const [certification, setCertification] = useState('');
    // const []

    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [{ project, projectlist }, setData] = useState({
        project: "Germany",
        projectlist: ""
    });

    const projecties = projectiesData.map((project) => (
        <option key={project.name} value={project.name}>
            {project.name}
        </option>
    ));

    const projectlists = projectiesData.find(item => item.name === project)?.projectlists.map((projectlist) => (
        <option key={projectlist} value={projectlist}>
            {projectlist}
        </option>
    ));

    function handleCountryChange(event) {
        setData(data => ({ projectlist: '', project: event.target.value }));
        setSelectedProject(event.target.value);
    }

    function handleStateChange(event) {
        setData(data => ({ ...data, projectlist: event.target.value }));
        setSelectedSubProject(event.target.value);
    }

    const handleChangeChechkedYes = () => {
        if (!isYesChecked) {
            setIsYesChecked(true);
            setIsNoChecked(false);
        } else {
            setIsYesChecked(false);
        }
    }

    const handleChangeChechkedNo = () => {
        if (!isNoChecked) {
            setIsNoChecked(true);
            setIsYesChecked(false);
        } else {
            setIsNoChecked(false);
        }
    }

    const handleRegistrationApi = () => {
        console.log(selectedProject, selectedSubProject, salutations, firstName, lastName, email, address, country, state, city, pinCode, countryCode, mobileNum, TeleNum, message, certification);

        if (!selectedProject) {
            setErrorMessage("Please Select Project!");
            setIsError(true);
            return;
        }
        // if (!selectedSubProject) {
        //     setErrorMessage("Please Select Sub Project!");
        //     setIsError(true);
        //     return;
        // }
        if (!salutations) {
            setErrorMessage("Please Select Salutations!");
            setIsError(true);
            return;
        }
        if (!firstName) {
            setErrorMessage("Please Enter First Name!");
            setIsError(true);
            return;
        }
        if (!lastName) {
            setErrorMessage("Please Enter Last Name!");
            setIsError(true);
            return;
        }
        if (!email) {
            setErrorMessage("Please Enter Email id!");
            setIsError(true);
            return;
        }
        if (!address) {
            setErrorMessage("Please Enter Address!");
            setIsError(true);
            return;
        }
        if (!country) {
            setErrorMessage("Please Select Country!");
            setIsError(true);
            return;
        }
        if (!state) {
            setErrorMessage("Please Select State!");
            setIsError(true);
            return;
        }
        if (!city) {
            setErrorMessage("Please Enter City!");
            setIsError(true);
            return;
        }
        if (!mobileNum) {
            setErrorMessage("Please Enter Mobile!");
            setIsError(true);
            return;
        }
        Axios.post('api/assocham/registration', {
            project_name: selectedProject,
            sub_project_name: selectedSubProject,
            salutations: salutations,
            first_name: firstName,
            last_name: lastName,
            email_id: email,
            address: address,
            country: country,
            state: state,
            city: city,
            pin_code: pinCode,
            mobile_code: countryCode,
            mobile_no: mobileNum,
            tele_no: TeleNum,
            message: message,
            certification: certification
        })
            .then((res) => {
                console.log(res);
            })
            .catch((error) => {
                console.log(error);
            })
    }


    return (
        <>
            <div className="resgist-bnr-sec">
                <div className="abt-hding">
                    <h2>Registration</h2>
                </div>
                <div className="bread-crb">
                    <ul>
                        <li><Link to=''>Home</Link></li><span><i className="flaticon-next"></i></span>
                        <li><Link to=''>Registration</Link></li>
                    </ul>
                </div>


            </div>

            <div className="abt-content-sec register-form-sec">
                <div className="container">
                    <div className="row">

                        <div className="col-md-12">
                            <div className="right-cont">
                                <h2>Register For GEM</h2>
                            </div>
                            <div className="register-form">
                                {/* <form action="" method="get"> */}
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="rgister-label">
                                            <label htmlFor="">Select Your Project *</label>
                                        </div>

                                    </div>
                                    <div className="col-sm-8">
                                        <div className="rgister-txt">
                                            <select value={selectedProject} onChange={handleCountryChange}>
                                                {projecties}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="rgister-label">
                                            <label htmlFor="">Select Your Sub Project *</label>
                                        </div>
                                    </div>

                                    <div className="col-md-8">
                                        <div className="rgister-txt">
                                            <select value={selectedSubProject} onChange={handleStateChange}>
                                                {projectlists}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="rgister-label">
                                            <label htmlFor="">Salutations *</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-8">
                                        <div className="radio-btn">
                                            <input class="form-check-input" type="radio" name="sal" value="Mr" onChange={(e) => setSalutations(e.target.value)} /><label>Mr</label>
                                            <input class="form-check-input" type="radio" name="sal" value="Ms" onChange={(e) => setSalutations(e.target.value)} /><label>Ms</label>
                                            <input class="form-check-input" type="radio" name="sal" value="Dr" onChange={(e) => setSalutations(e.target.value)} /><label>Dr</label>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="rgister-txt">
                                            <label htmlFor="">First Name *</label>

                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="rgister-txt">
                                            <input type="text" name="fname" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="form-input" />
                                        </div>

                                    </div>
                                    <div className="col-md-4">
                                        <div className="rgister-txt">
                                            <label htmlFor="">Last Name *</label>

                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="rgister-txt">
                                            <input type="text" name="lname" value={lastName} onChange={(e) => setLastName(e.target.value)} className="form-input" />
                                        </div>

                                    </div>

                                    <div className="col-md-4">
                                        <div className="rgister-txt">
                                            <label htmlFor="">Email Id *</label>

                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="rgister-txt">
                                            <input type="email" name="lname" value={email} onChange={(e) => setEmail(e.target.value)} className="form-input" />
                                        </div>

                                    </div>

                                    <div className="col-md-4">
                                        <div className="rgister-txt">
                                            <label htmlFor="">Address Line *</label>

                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="rgister-txt">
                                            <input type="text" name="lname" value={address} onChange={(e) => setAddress(e.currentTarget.value)} className="form-input" />
                                        </div>

                                    </div>

                                    <div className="col-md-4">
                                        <div className="rgister-txt">
                                            <label htmlFor="">Country*</label>

                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="rgister-txt">
                                            <select name="country" id="country" value={country} onChange={(e) => setCountry(e.target.value)}>
                                                <option >Select Country</option>
                                                <option value="india">India</option>

                                            </select>
                                        </div>

                                    </div>

                                    <div className="col-md-4">
                                        <div className="rgister-txt">
                                            <label htmlFor="">State *</label>

                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="rgister-txt">
                                            <select id="country-state" name="country-state" value={state} onChange={(e) => setState(e.target.value)}>
                                                <option value="slct">Select State</option>
                                                <option value="AN">Andaman and Nicobar Islands</option>
                                                <option value="AP">Andhra Pradesh</option>
                                                <option value="AR">Arunachal Pradesh</option>
                                                <option value="AS">Assam</option>
                                                <option value="BR">Bihar</option>
                                                <option value="CH">Chandigarh</option>
                                                <option value="CT">Chhattisgarh</option>
                                                <option value="DN">Dadra and Nagar Haveli</option>
                                                <option value="DD">Daman and Diu</option>
                                                <option value="DL">Delhi</option>
                                                <option value="GA">Goa</option>
                                                <option value="GJ">Gujarat</option>
                                                <option value="HR">Haryana</option>
                                                <option value="HP">Himachal Pradesh</option>
                                                <option value="JK">Jammu and Kashmir</option>
                                                <option value="JH">Jharkhand</option>
                                                <option value="KA">Karnataka</option>
                                                <option value="KL">Kerala</option>
                                                <option value="LA">Ladakh</option>
                                                <option value="LD">Lakshadweep</option>
                                                <option value="MP">Madhya Pradesh</option>
                                                <option value="MH">Maharashtra</option>
                                                <option value="MN">Manipur</option>
                                                <option value="ML">Meghalaya</option>
                                                <option value="MZ">Mizoram</option>
                                                <option value="NL">Nagaland</option>
                                                <option value="OR">Odisha</option>
                                                <option value="PY">Puducherry</option>
                                                <option value="PB">Punjab</option>
                                                <option value="RJ">Rajasthan</option>
                                                <option value="SK">Sikkim</option>
                                                <option value="TN">Tamil Nadu</option>
                                                <option value="TG">Telangana</option>
                                                <option value="TR">Tripura</option>
                                                <option value="UP">Uttar Pradesh</option>
                                                <option value="UT">Uttarakhand</option>
                                                <option value="WB">West Bengal</option>
                                            </select>
                                        </div>

                                    </div>

                                    <div className="col-md-4">
                                        <div className="rgister-txt">
                                            <label htmlFor="">City *</label>

                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="rgister-txt">
                                            <input type="text" name="lname" value={city} onChange={(e) => setCity(e.target.value)} className="form-input" />
                                        </div>

                                    </div>

                                    <div className="col-md-4">
                                        <div className="rgister-txt">
                                            <label htmlFor="">Pincode</label>

                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="rgister-txt">
                                            <input type="text" name="lname" value={pinCode} onChange={(e) => setPinCode(e.target.value)} className="form-input" />
                                        </div>

                                    </div>

                                    <div className="col-md-4">
                                        <div className="rgister-txt">
                                            <label htmlFor="">Mobile No. (With Country Code)*</label>

                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="rgister-txt">
                                            <input type="text" class="form-control valid" id="country_code" name="country_code" style={{ width: "10%", marginRight: "10px" }} value={countryCode} onChange={(e) => setCountryCode(e.target.value)} readonly="" />
                                            <input type="text" class="form-control" id="mobile" name="mobile" value={mobileNum} onChange={(e) => setMobileNum(e.target.value)} title="Mobile No is required." required="" style={{ width: "84%", marginLeft: "10px" }} />
                                        </div>

                                    </div>

                                    <div className="col-md-4">
                                        <div className="rgister-txt">
                                            <label htmlFor="">Telephone No. (With STD Code)</label>

                                        </div>

                                    </div>

                                    <div className="col-md-8">
                                        <div className="rgister-txt">
                                            <input type="text" name="lname" value={TeleNum} onChange={(e) => setTeleNum(e.target.value)} className="form-input" />
                                        </div>

                                    </div>
                                    <div className="col-md-4">
                                        <div className="rgister-txt">
                                            <label htmlFor="">Message</label>

                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="rgister-txt">
                                            <textarea name="msg" id="comt" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Brief Project description"></textarea>

                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="condition-txt">
                                            <span>have you undertaken any other certification? ()</span>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="condition-txt">
                                            {/* <input type="checkbox" class="form-check-input" id="tnc" name="tnc" value="1" required="" />  I have read and agree to the <Link to=''>Terms and Condition</Link><span class="star">*</span> */}

                                            <ul>
                                                <li>
                                                    <label>Yes</label><input type="checkbox" checked={isYesChecked} class="form-check-input" id="tnc" name="tnc" required="" onClick={handleChangeChechkedYes} />
                                                </li>
                                                <li><label>No</label><input type="checkbox" checked={isNoChecked} class="form-check-input" id="tnc" name="tnc" required="" onClick={handleChangeChechkedNo} /></li>
                                            </ul>

                                            {
                                                isYesChecked ? <>
                                                    <div className="selct-iso">
                                                        <select name="" id="" value={certification} onChange={(e) => setCertification(e.target.value)}>
                                                            <option value="iso1">ISO:9001</option>
                                                            <option value="iso2">ISO: 14001</option>
                                                            <option value="iso3">ISO: 45001</option>
                                                            <option value="iso4">Others</option>
                                                        </select>
                                                    </div>
                                                </> : ""
                                            }
                                        </div>

                                    </div>
                                    {/* <div className="col-md-12">
                                            <div className="condition-txt">
                                            <input type="checkbox" class="form-check-input" id="tnc" name="tnc" value="1" required="" />  I have read and agree to the <Link to=''>Terms and Condition</Link><span class="star">*</span>

                                            </div>

                                        </div> */}


                                    <div class="col-md-12">
                                        <div className="sub-bnt">
                                            {isError ? <span>{errorMessage}</span> : ""}

                                            <button type="submit" class="btn btn-primary text-center" onClick={handleRegistrationApi} name="btn_registration">Submit</button>
                                            <button type="reset" class="btn btn-danger text-center" name="btn_cancel">Cancel</button>
                                        </div>


                                    </div>

                                </div>
                                {/* </form> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}


export default Registration;