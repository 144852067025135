import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './mcqStyle.css';
import { Link, useNavigate } from "react-router-dom";
import data from './data.json';

const QuestionList = () => {
    const Navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);
    const [QuestionPaper, setQuestionPaper] = useState(data.questions);
    const [isActive, setIsActive] = useState(false)
    const [isChecked, setIschecked] = useState(false);
    // console.log(QuestionPaper)
    // useEffect(() => {
    //     setQuestionPaper(data);
    // }, [])

    // const questionPaperList = QuestionPaper.map((item, index) => {
    //     return (
    //         <div className="mcq-form-cont" key={index}>
    //             <h5><span>{index + 1}</span>{item.question}</h5>
    //             <ul className="mcq-choies">
    //                 {
    //                     item.answers.map((ans, inde) => {
    //                         return (<li key={inde}><label><input type="radio" name="question2" value="A"></input><span>{ans[inde]}</span></label></li>)
    //                         inde = inde + 1;
    //                     })
    //                 }
    //             </ul>
    //         </div>
    //     )
    // })

    const handleNextButton = () => {
        if (tabIndex < 9) {
            setTabIndex(tabIndex + 1);
        }
    }

    const handlePrevButton = () => {
        if (tabIndex > 0) {
            setTabIndex(tabIndex - 1);
        }
    }
    const handleProcced = () => {
        if (!isChecked) {
            alert("Please Select CheckBox");
            return;
        }
        Navigate('/mcq?isAllow=yes&paper_id=13');
        // Navigate('/exam?isAllow=yes&paper_id=12');

    }
    return (
        <>

            <div className="instr-bnr-sec">
                <div className="mcq-cont">
                    <h2>Instructions</h2>
                </div>
                <div class="bread-crb"><ul><li><Link to="/home">Home</Link></li><span><i class="flaticon-next"></i></span><li><Link to="">Instructions</Link></li></ul></div>
            </div>

            <div className="instr-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="instr-cont">
                                <h3>Please read the instructions carefully</h3>
                                <ul>
                                    <li>1- The clock will be set at the server. The countdown timer at the top left corner of screen will display the remaining time available to you for completing the examination. When the time ends, the examination will end and the test gets submitted automatically.</li>
                                    <li>2- To Submit the test, click on the Submit button present at the right top of the screen.</li>
                                    <li>3- Change Section dropdown is given at the top to change the subject/section in the Paper.</li>
                                    <li>4- On clicking Submit button, test will be submitted automatically.</li>
                                </ul>

                                <h3>Answering a Question:</h3>
                                <p>Procedure for answering a multiple choice question:</p>
                                <ul>
                                    <li>1. To select your answer, click on the correct option.</li>
                                    <li>2 To deselect your chosen answer, click on the button of the chosen option again ..</li>
                                    <li>3 To change your chosen answer, click on the other option.</li>
                                    <li>4 To mark the question for review, click on the Mark for Review button.</li>
                                    <li>5 To go on previous question you can click on the Previous button & to go on next click on Save & Next button on the question screen.</li>
                                    <li>6 After completing the test - To submit the exam you can click on the Submit button present on the top right corner of the screen.</li>
                                </ul>
                                <p>To change your answer to a question that has already been answered, first select that question for answering and then follow the procedure for answering that type of question.</p>
                            </div>

                            <form action="">
                                <div className="decdisclaimer">
                                    <label><input type="checkbox" checked={isChecked} onChange={(e) => setIschecked(e.target.value)} name="text" required />I have read and understood the instructions. All the computer hardware allotted to me are in a proper working condition. I declare that I am not in possession of, carrying or wearing any prohibited gadget, such as mobile phone, bluetooth devices, or any other prohibited material (notes, chits, etc.) into the examination hall. I affirm that in case of not adhering to the above instructions, I shall be liable to be debarred from this test and/or be subjected to disciplinary action, which may include a ban from future tests/ examinations.</label>
                                    <div className="proced-btn">
                                        <button onClick={handleProcced}>Proceed</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>


            </div>



        </>
    )
}


export default QuestionList;