import React, { useEffect, useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { geoMercator, geoPath } from "d3-geo";
import INDIA_TOPO_JSON from "./pract.topo.json";
import "./../../App.css";
import { Link } from 'react-router-dom';
import Axios from '../../services/AxiosInstance';
import Loader from '../common/Loader';

const MapChart = () => {
    const [personsList, setPersonsList] = useState([]);
    const [hoveredState, setHoveredState] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    // Define the projection using the D3-geo library
    const projection = geoMercator().center([85, 25]).scale(1000);

    // Define a function to create the path generator using the projection
    const pathGenerator = geoPath().projection(projection);

    const getAllStateData = () => {
        Axios.get('api/assocham/get-gem-state-chapter-persons', setIsLoading(true))
            .then((res) => {
                setPersonsList(res.data.data);
                setIsLoading(false);
                console.log("sads", res.data.data);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            })

    }

    useEffect(() => {
        getAllStateData();
    }, []);


    const showTableList = personsList.map((item, index) => {
        return (
            <tr key={index}>
                <td>{item.chairman_name}</td>
                <td>{item.co_chairman_name}</td>
                <td><Link to={`/gemStateChapters?state_name="${item.state_name}"`}>ASSOCHAM GEM {item.state_name} Chapter</Link></td>
            </tr>
        )
    })


    if (isLoading) {
        return (
            <div className="container-fluid">
                <div className="row">
                    <Loader />
                </div>
            </div>
        )
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4">
                    <div className="map-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Chairman</th>
                                    <th>Co-Chairman</th>
                                    <th>State</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showTableList}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="col-md-8">
                    {hoveredState && (
                        <div className="popup">
                            <h3>{hoveredState}</h3>
                            <ul>
                                {personsList.map((persons) => {
                                    if (persons.state_name == hoveredState) {
                                        return (
                                            <div>
                                                <p>Chairman Name: {persons.chairman_name}</p>
                                                <p>Co-Chairman Name: {persons.co_chairman_name}</p>
                                            </div>
                                        );
                                    }
                                })}
                            </ul>
                        </div>
                    )}

                    <div className="map-css">
                        <ComposableMap projection={projection}>
                            <Geographies geography={INDIA_TOPO_JSON}>
                                {({ geographies }) =>
                                    geographies.map((geo) => {
                                        const centroid = pathGenerator.centroid(geo);
                                        const stateData = personsList.find((d) => d.state_name === geo.properties.name) || [];

                                        const showCircle = stateData && stateData?.state_name === geo.properties.name;
                                        const fillColor = showCircle ? "#00BFFF" : "#D6D6DA";


                                        return (
                                            <React.Fragment key={geo.rsmKey}>
                                                <Geography
                                                    geography={geo}
                                                    value={geo}
                                                    style={{
                                                        default: {
                                                            fill: fillColor,
                                                            outline: "none",
                                                            stroke: "#000",
                                                            strokeWidth: 0.3,
                                                        },
                                                        hover: {
                                                            fill: "#F53",
                                                            outline: "none",
                                                        },
                                                    }}
                                                    path={pathGenerator(geo)}
                                                    onMouseEnter={() => setHoveredState(geo.properties.name)}
                                                    onMouseLeave={() => setHoveredState(null)}
                                                />
                                                {showCircle && centroid && (
                                                    <circle
                                                        cx={centroid[0]}
                                                        cy={centroid[1]}
                                                        r={2}
                                                        fill="#000"
                                                    />
                                                )}
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </Geographies>
                        </ComposableMap>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default MapChart;
