import React from "react";
import './event.css';
import { Link } from "react-router-dom";
import eventnewimg1 from '../../assets/img/event-new-img1.jpg';
import eventnewimg2 from '../../assets/img/event-new-img2.jpg';
import eventnewimg3 from '../../assets/img/event-new-img3.jpg';
import eventnewimg4 from '../../assets/img/event-new-img4.jpg';
import eventnewimg5 from '../../assets/img/event-new-img5.jpg';
import eventnewimg6 from '../../assets/img/event-new-img6.jpg';





const eventTwo = () => {
    return (
        <>
            <div className="gem-certifiat-secs">
                <div className="abt-hding">
                    <h2>Event </h2>
                </div>
                <div className="bread-crb">
                    <ul>
                        <li><Link to=''>Home</Link></li><span><i className="flaticon-next"></i></span>
                        <li><Link to=''>Event </Link></li>
                    </ul>
                </div>
            </div>

            <div className="gem-certifiat--cont-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="right-cont gem-leader">
                                <h2>Event</h2>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="event-colm1">
                                    <div className="event-img">
                                            <img src={eventnewimg1} alt="" />
                                        </div>
                                        <div className="event-cont2">
                                            <h3>Webinar on Interlinking of UNSDGs and Green Educational Campuses through ‘Not Zero Net Zero’</h3>
                                            <h6><i className="flaticon-date"></i>Thursday 10th, June 2021</h6>
                                        </div>
                                        <div className="redmore-btn">
                                        <Link to=''>Read More</Link>
                                        </div>
                                    
                                
                            </div>
                        </div>

                        <div className="col-md-4">
                        <div className="event-colm1">
                                    <div className="event-img">
                                            <img src={eventnewimg2} alt="" />
                                        </div>
                                        <div className="event-cont2">
                                            <h3>Online Master Class on All about GEM Sustainability Certification Program and GEM Certified Professional Online Exam</h3>
                                            <h6><i className="flaticon-date"></i> Saturday 26th, June 2021</h6>
                                        </div>
                                        <div className="redmore-btn">
                                        <Link to=''>Read More</Link>
                                        </div>
                                    
                                
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="event-colm1">
                                    <div className="event-img">
                                            <img src={eventnewimg3} alt="" />
                                        </div>
                                        <div className="event-cont2">
                                            <h3>GEM Certified Professional (GEM CP) Online Exam</h3>
                                            <h6><i className="flaticon-date"></i>Saturday 8th, May 2021</h6>
                                        </div>
                                        <div className="redmore-btn">
                                        <Link to=''>Read More</Link>
                                        </div>
                                    
                                
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="event-colm1">
                                    <div className="event-img">
                                            <img src={eventnewimg4} alt="" />
                                        </div>
                                        <div className="event-cont2">
                                            <h3>‘ASSOCHAM GEM & Axis Colleges Student Chapter’s NISHCHAY – IV “Energy Efficiency by Cool Roofs”</h3>
                                            <h6><i className="flaticon-date"></i> Friday 5th, February 2021</h6>
                                        </div>
                                        <div className="redmore-btn">
                                        <Link to=''>Read More</Link>
                                        </div>
                                    
                                
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="event-colm1">
                                    <div className="event-img">
                                            <img src={eventnewimg5} alt="" />
                                        </div>
                                        <div className="event-cont2">
                                            <h3>ASSOCAHM Virtual Realty & Sustainability Conference, Expo & Awards</h3>
                                            <h6><i className="flaticon-date"></i> Thursday 3rd, December 2020</h6>
                                        </div>
                                        <div className="redmore-btn">
                                        <Link to=''>Read More</Link>
                                        </div>
                                    
                                
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="event-colm1">
                                    <div className="event-img">
                                            <img src={eventnewimg6} alt="" />
                                        </div>
                                        <div className="event-cont2">
                                            <h3>Webinar on 15 Tips to Build and Design Sustainability</h3>
                                            <h6><i className="flaticon-date"></i> Thursday 5th, November 2020</h6>
                                        </div>
                                        <div className="redmore-btn">
                                        <Link to=''>Read More</Link>
                                        </div>
                                    
                                
                            </div>
                        </div>

                        

                    </div>
                </div>
            </div>


        </>
    )
}


export default eventTwo;