import React from "react";
import MapChart from "./MapChart";

const AssochamGemChapter = () => {
    return (
        <div>
            <MapChart />
        </div>
    )
}

export default AssochamGemChapter;