import React, { useState, useEffect } from "react";
import './home.css';
import countericon1 from '../../assets/img/archi-icon.png';
import countericon2 from '../../assets/img/webniar-icon.png';
import countericon3 from '../../assets/img/participat-icon.png';
import countericon4 from '../../assets/img/counting-img3.png';
import countericon5 from '../../assets/img/counting-img4.png';
import countericon7 from '../../assets/img/counting-img6.png';
import countericon6 from '../../assets/img/Institut-img.png';
import ratingicons1 from '../../assets/img/certificate-icon.png';
import ratingicons2 from '../../assets/img/varientIcon5-active.png';
import ratingicons3 from '../../assets/img/varientIcon1-active.png';
import ratingicons4 from '../../assets/img/varientIcon3-active.png';
import ratingicons5 from '../../assets/img/health-icon.png';
import ratingicons8 from '../../assets/img/varientIcon7-active.png';
import ratingicons11 from '../../assets/img/port-img.png'
import ratingicons9 from '../../assets/img/airport-icon.png';
import ratingicons10 from '../../assets/img/railway-icon.png';
import ratingicons12 from '../../assets/img/shiping-icon.png'
import socialimg1 from '../../assets/img/social-img1.jpg';
import socialimg2 from '../../assets/img/social-img2.jpg';
import socialimg3 from '../../assets/img/social-img3.jpg';
import successStr1 from '../../assets/img/success-stories-1.jpeg';
import successStr2 from '../../assets/img/success-stories-2.jpeg';
import successStr3 from '../../assets/img/success-stories-3.jpeg';
import successStr4 from '../../assets/img/success-stories-4.jpeg';
import successStr5 from '../../assets/img/success-stories-5.jpeg';
import successStr6 from '../../assets/img/success-stories-6.jpeg';
import successStr7 from '../../assets/img/success-stories-7.jpeg';
import successStr8 from '../../assets/img/success-stories-8.jpg';
import data from "./data";
import '../../assets/fonts/flaticon.css';
import Axios from '../../services/AxiosInstance';
import { Link } from 'react-router-dom';

const Home = () => {
    const [formSelected, setFormSelected] = useState("0");
    const [people] = useState(data);
    const [index, setIndex] = useState(0);
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [message, setMessage] = useState();
    const [mobile_no, setMobileNo] = useState();
    const [projectName, setProjectName] = useState("Project-1");
    const [changeKey,setChangeKey] = useState();
    const [changeValue,setChangeValue] = useState();

    useEffect(() => {
        const lastIndex = people.length - 1;
        if (index < 0) {
            setIndex(lastIndex);
        }
        if (index > lastIndex) {
            setIndex(0);
        }
    }, [index, people]);

    // autoslide, clearInterval = een cleanup functie noodzakelijk bij interval
    useEffect(() => {
        let slider = setInterval(() => {
            setIndex(index + 1);
        }, 3000);
        return () => clearInterval(slider);
    }, [index]);


    const setFormSelectedFunc = (e) => {
        setFormSelected(e.target.value);
        setProjectName(e.target.options[e.target.selectedIndex].text, e.target.value);
    }

    const setChangableFunc = (e) => {
        let changeKey1 = e.target.name;
        let changeValue1 = e.target.value;
        setChangeKey(changeKey1);
        setChangeValue(changeValue1);
    }


    const submitCertificationEnquiry = (e) => {
        e.preventDefault();
        console.log(firstName,lastName,changeKey,changeValue);
        Axios.post(`api/assocham/insert-gem-certification-enquiry`, {
            project_name: projectName,
            first_name: firstName,
            last_name: lastName,
            // mobile_no: mobile_no,
            // message: message,
            // email : email,
            change_key : changeKey,
            change_value : changeValue
        })
            .then((res) => {
                console.log(res);
            })
            .catch((error) => {
                console.log(error);
            })
        return;
    }

    return (
        <>
            <section className="section">
                <div className="section-center">
                    {people.map((person, personIndex) => {
                        const { id, image, name } = person;
                        let position = "nextSlide";
                        if (personIndex === index) {
                            position = "activeSlide";
                        }
                        if (
                            personIndex === index - 1 ||
                            (index === 0 && personIndex === people.length - 1)
                        ) {
                            position = "lastSlide";
                        }
                        return (
                            <article key={id} className={position}>
                                <div className="single-slider bg-img">
                                    <img src={image} alt={name} />
                                </div>
                            </article>
                        );
                    })}
                    <button className="prev" onClick={() => setIndex(index - 1)}>
                        <i class="flaticon-left-arrow"></i>
                    </button>   ``
                    <button className="next" onClick={() => setIndex(index + 1)}>
                        <i class="flaticon-next"></i>
                    </button>
                </div>
            </section>
            {/* <div className="slide-sec">
                <div className="slider-img">
                    <img src={sliderimg1} alt="" />
                </div>
            </div> */}
            <div className="counting-sec">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2">
                            <div class="count-col">
                                <div class="icon-img">
                                    <img src={countericon1} alt="" />
                                </div>
                                <div class="count-cont">
                                    <span class="counter">1800</span><span>+</span>
                                    <h4>Architects</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div class="count-col">
                                <div class="icon-img">
                                    <img src={countericon2} alt="" />
                                </div>
                                <div class="count-cont">
                                    <span class="counter">1000</span><span>+</span>
                                    <h4>Webinars</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div class="count-col">
                                <div class="icon-img">
                                    <img src={countericon3} alt="" />
                                </div>
                                <div class="count-cont">
                                    <span class="counter">50000</span><span>+</span>
                                    <h4>Participants Covered</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div class="count-col">
                                <div class="icon-img">
                                    <img src={countericon4} alt="" />
                                </div>
                                <div class="count-cont">
                                    <span class="counter">5000</span><span>+</span>
                                    <h4>GEM CP</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div class="count-col">
                                <div class="icon-img">
                                    <img src={countericon5} alt="" />
                                </div>
                                <div class="count-cont">
                                    <span class="counter">25</span><span>+</span>
                                    <h4>GEM Consultants </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="counting-new-sec">
                <div class="count-col">
                    <div class="icon-img">
                        <img src={countericon3} alt="" />
                    </div>
                    <div class="count-cont">
                        <span class="counter">150000</span>
                        <span>+</span>
                        <h4>Participants</h4>

                    </div>
                </div>
                <div class="count-col">
                    <div class="icon-img">
                        <img src={countericon5} alt="" />
                    </div>
                    <div class="count-cont">
                        <span class="counter">2000</span>
                        <span>+</span>
                        <h4>Professional</h4>
                    </div>
                </div>
                <div class="count-col">
                    <div class="icon-img">
                        <img src={countericon4} alt="" />
                    </div>
                    <div class="count-cont">
                        <span class="counter">1500</span>
                        <span>+</span>
                        <h4>Certified Professional</h4>
                    </div>
                </div>
                <div class="count-col">
                    <div class="icon-img">
                        <img src={countericon2} alt="" />
                    </div>
                    <div class="count-cont">
                        <span class="counter">200</span>
                        <span>+</span>
                        <h4>Conference & Webinar</h4>
                    </div>
                </div>

                <div class="count-col">
                    <div class="icon-img">
                        <img src={countericon6} alt="" />
                    </div>
                    <div class="count-cont">
                        <span class="counter">100</span>
                        <span>+</span>
                        <h4>Institutional MOU</h4>
                    </div>
                </div>
                <div class="count-col">
                    <div class="icon-img">
                        <img src={countericon7} alt="" />
                    </div>
                    <div class="count-cont">
                        <span class="counter">30</span>
                        <span>+</span>
                        <h4>knowledge Consulting Partners</h4>
                    </div>
                </div>
                <div class="count-col">
                    <div class="icon-img">
                        <img src={countericon5} alt="" />
                    </div>
                    <div class="count-cont">
                        <span class="counter">10</span>
                        <span>+</span>
                        <h4>Professional MOU</h4>
                    </div>
                </div>


            </div>
            <div className="rating-sec">
                <div class="rating-row">
                    {/* <h2>GEM Certified Professional</h2> */}
                    <h2>Sustainability Services</h2>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-md-7">

                            <div class="row">
                                <div class="col-md-4">
                                    <div class="rating-cont">
                                        <div class="rating-img">
                                            <img src={ratingicons8} alt="" />
                                        </div>
                                        <h3><Link to='/gemCertificationSystem?menu_id=75&sub_menu_id=0' style={{ color: "#474646" }}>GEM Green Building Certification</Link></h3>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="rating-cont">
                                        <div class="rating-img">
                                            <img src={ratingicons4} alt="" />
                                        </div>
                                        <h3>GEM Eco-Product Certification</h3>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="rating-cont">
                                        <div class="rating-img">
                                            <img src={ratingicons3} alt="" />
                                        </div>
                                        <h3>Carbon Footprinting</h3>
                                    </div>
                                </div>


                                <div class="col-md-4">
                                    <div class="rating-cont">
                                        <div class="rating-img">
                                            <img src={ratingicons5} alt="" />
                                        </div>
                                        <h3>Sustainability Assessment</h3>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="rating-cont">
                                        <div class="rating-img">
                                            <img src={ratingicons10} alt="" />
                                        </div>
                                        <h3>Criteria VII (Environmental parameters) of NAAC</h3>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="rating-cont">
                                        <div class="rating-img">
                                            <img src={ratingicons1} alt="" />
                                        </div>
                                        <h3>ISO 14001 conformity</h3>
                                    </div>
                                </div>


                                <div class="col-md-4">
                                    <div class="rating-cont">
                                        <div class="rating-img">
                                            <img src={ratingicons9} alt="" />
                                        </div>
                                        <h3>Environmental Management Plans</h3>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="rating-cont">
                                        <div class="rating-img">
                                            <img src={ratingicons11} alt="" />
                                        </div>
                                        <h3>Water Audits</h3>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="rating-cont">
                                        <div class="rating-img">
                                            <img src={ratingicons12} alt="" />
                                        </div>
                                        <h3>Energy Audits</h3>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-xl-5 col-md-5">
                            <div className="cal-col">
                                <div class="cal-hd">
                                    <h2>GEM Certification Enquiry </h2>
                                </div>
                                <div className="quote-form">
                                    <form onSubmit={submitCertificationEnquiry}>
                                        <label> Select Project </label>
                                        <select onChange={(e) => setFormSelectedFunc(e)}>
                                            <option value="0">Project-1</option>
                                            <option value="1">Project-1</option>
                                            <option value="2">Project-1</option>
                                        </select>
                                        <div className="select-form">

                                            <label htmlFor="">First Name</label>
                                            <input type="text" name="fname" className="form-control" onChange={(e) => { setFirstName(e.target.value) }} />
                                            <label htmlFor="">Last Name</label>
                                            <input type="text" name="lname" className="form-control" onChange={(e) => { setLastName(e.target.value) }} />
                                        </div>

                                        {
                                            formSelected === "0" ?
                                                <>
                                                    <div className="select-form">

                                                        {/* <label htmlFor="">First Name</label>
                                                        <input type="text" name="fname" className="form-control" />
                                                        <label htmlFor="">Last Name</label>
                                                        <input type="text" name="lname" className="form-control" /> */}
                                                        <label htmlFor="">Email </label>
                                                        <input type="email" name="email" className="form-control" onChange={(e) => { setChangableFunc(e) }} />
                                                    </div>
                                                </> : ""
                                        }
                                        {
                                            formSelected === "1" ?
                                                <>
                                                    <div className="select-form">

                                                        {/* <label htmlFor="">First Name</label>
                                                        <input type="text" name="fname" className="form-control" />
                                                        <label htmlFor="">Last Name</label>
                                                        <input type="text" name="lname" className="form-control" /> */}
                                                        <label htmlFor="">Phone Number </label>
                                                        <input type="text" name="mobile_no" className="form-control" onChange={(e) => { setChangableFunc(e) }} />
                                                    </div>
                                                </> : ""
                                        }
                                        {
                                            formSelected === "2" ?
                                                <>
                                                    <div className="select-form">

                                                        {/* <label htmlFor="">First Name</label>
                                                        <input type="text" name="fname" className="form-control" />
                                                        <label htmlFor="">Last Name</label>
                                                        <input type="text" name="lname" className="form-control" /> */}
                                                        <label htmlFor="">Message </label>
                                                        <input type="text" name="message" className="form-control" onChange={(e) => { setChangableFunc(e) }} />
                                                    </div>
                                                </> : ""
                                        }
                                        <button name="submit" type="submit" className="sub-btn" > Submit</button>
                                    </form>
                                </div>

                                <p>Certification fee provided here is tentative</p>
                            </div>
                            {/* <div class="regitr-btn">
                                <a href="/#">Join GEM Gallaxy</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="story-sec">
                <div class="rating-row">
                    <h2>Success Stories</h2>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-md-4">
                            <div class="case-col">
                                <div class="case-img">
                                    <img src={successStr1} alt="" />
                                </div>
                                {/* <div class="case-cont">
                                    <p><span>Title:</span>Webinar on Interlinking of UNSDGs and Green Educational Campuses through ‘Not Zero Net Zero’</p>
                                    <p><span>Location:</span>New Delhi</p>
                                    <p><span>Rating Type:</span>Provisional Rating</p>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <div class="case-col">
                                <div class="case-img">
                                    <img src={successStr3} alt="" />
                                </div>
                                {/* <div class="case-cont">
                                    <p><span>Title:</span>Professional Training on GEM Sustainability Certification Program and GEM CP Exam</p>
                                    <p><span>Location:</span>India Habitat Center, New Delhi</p>
                                    <p><span>Rating Type:</span>Provisional Rating</p>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <div class="case-col">
                                <div class="case-img">
                                    <img src={successStr4} alt="" />
                                </div>
                                {/* <div class="case-cont">
                                    <p><span>Title:</span>Professional Training on GEM Sustainability Certification Program and GEM CP Exam</p>
                                    <p><span>Location:</span>India Habitat Center, New Delhi</p>
                                    <p><span>Rating Type:</span>Provisional Rating</p>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-xl-4 col-md-4">
                            <div class="case-col">
                                <div class="case-img">
                                    <img src={successStr6} alt="" />
                                </div>
                                {/* <div class="case-cont">
                                    <p><span>Title:</span>Professional Training on GEM Sustainability Certification Program and GEM CP Exam</p>
                                    <p><span>Location:</span>India Habitat Center, New Delhi</p>
                                    <p><span>Rating Type:</span>Provisional Rating</p>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-xl-4 col-md-4">
                            <div class="case-col">
                                <div class="case-img">
                                    <img src={successStr7} alt="" />
                                </div>
                                {/* <div class="case-cont">
                                    <p><span>Title:</span>Professional Training on GEM Sustainability Certification Program and GEM CP Exam</p>
                                    <p><span>Location:</span>India Habitat Center, New Delhi</p>
                                    <p><span>Rating Type:</span>Provisional Rating</p>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-xl-4 col-md-4">
                            <div class="case-col">
                                <div class="case-img">
                                    <img src={successStr8} alt="" />
                                </div>
                                {/* <div class="case-cont">
                                    <p><span>Title:</span>Professional Training on GEM Sustainability Certification Program and GEM CP Exam</p>
                                    <p><span>Location:</span>India Habitat Center, New Delhi</p>
                                    <p><span>Rating Type:</span>Provisional Rating</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="social-sec">
                <div class="rating-row">
                    <h2>Social Feed</h2>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-md-4">
                            <div class="social-feed">
                                <div class="social-img">
                                    <img src={socialimg1} alt="" />
                                </div>
                                <div class="social-cont">
                                    <p>ASSOCHAM announces its mega flagship event
                                        GEM 5th Sustainability Conclave, Expo and Awards on the 20-21 January 2023 @TAJ MAHAL hotel, Lucknow.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <div class="social-feed">
                                <div class="social-img">
                                    <img src={socialimg2} alt="" />
                                </div>
                                <div class="social-cont">
                                    <p>We are proud to have Mehta Tubes Limited as the HVAC PARTNER for ASSOCHAM GEM 5th International Sustainability Conclave, Expo &amp; Awards 2023 scheduled to be held on Jan 20-21 at Hotel Taj Mahal, Lucknow.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-4">
                            <div class="social-feed">
                                <div class="social-img">
                                    <img src={socialimg3} alt="" />
                                </div>
                                <div class="social-cont">
                                    <ul>
                                        <li>GEM Sustainability Certification Program Reference Guide</li>
                                        <li>ASSOCHAM GEM Reference Guide for Existing Building</li>
                                        <li>GEM Certified Professional Online Exam Sample Paper</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </>
    )
}

export default Home;