import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './mcqStyle.css';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import data from './data.json';

import clockimg from "../../assets/img/clockIcon.png"
import { Modal, Button } from "react-bootstrap";
import Axios from '../../services/AxiosInstance';
import RenderHtml from "../common/htmlRender";

const QuestionList = () => {
	const [searchParam] = useSearchParams();
	const Navigate = useNavigate();

	const [tabIndex, setTabIndex] = useState(0);
	const [QuestionPaper, setQuestionPaper] = useState(data.questions);
	const [areyoursuretoSubmitpopup, setAreYouSureToSubmitPopup] = useState(false);

	const [showOpen, setShowOpen] = useState(false);
	const [modelClose, setModelClose] = useState(false);

	const [time, setTime] = useState(1);
	const [expired, setExpired] = useState(false);



	useEffect(() => {
		if (searchParam.get('isAllow') !== 'yes') {
			Navigate('/Instructions')
		}
		getTestPaperData();
		console.log('ds');
	}, [])


	const getTestPaperData = () => {
		Axios.get(`api/assocham/get-test-paper-data?paper_id=${searchParam.get('paper_id')}`)
			.then((res) => {
				console.log("sfdj", res.data.data.total_sec);
				setQuestionPaper(res.data.data.all_question);
				setTime(res.data.data.total_sec);
			})
			.catch((error) => {
				console.log(error);
			})
	}
	const closeModal = () => {
		setShowOpen(false);
		setModelClose(true);
		Navigate('/')
	};

	const handleSelectOption = (e, selectedOption) => {

		let selected = "option" + `${selectedOption}`;
		console.log(selected);
		let tempQuestion = QuestionPaper.map((item) => {
			if ((item.question_id === e.target.value) && (item.selectedOption === selectedOption)) {
				return { ...item, selectedOption: null }
			}
			return item.question_id === e.target.value ? { ...item, selectedOption: selected } : item
		});
		console.log(tempQuestion);
		setQuestionPaper(tempQuestion);
	}

	function formatTime(seconds) {
		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);
		const secondsRemaining = seconds % 60;

		const formattedHours = hours.toString().padStart(2, '0');
		const formattedMinutes = minutes.toString().padStart(2, '0');
		const formattedSeconds = secondsRemaining.toString().padStart(2, '0');

		return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
	}

	useEffect(() => {
		const intervalId = setInterval(() => {
			setTime(prevTime => prevTime - 1);
		}, 1000);

		if (time <= 0) {
			setExpired(true);
			clearInterval(intervalId);
			// Call the function to auto-submit the exam
			setShowOpen(true);
		}

		return () => clearInterval(intervalId);
	}, [time]);


	const questionPaperList = QuestionPaper.map((item, index) => {
		return (
			<TabPanel>
				<div className="tab-conts" key={index}>
					<h4><span>{index + 1}. </span><RenderHtml htmlString={item.question} /></h4>
					<ul className="tab-list-qustion">
						<li className={item.selectedOption && (item.selectedOption === ("option" + `${1}`)) ? "selected" : ""} value={item.question_id} onClick={(e) => handleSelectOption(e, 1)}>
							{/* <RenderHtml htmlString={item.option1} /> */}{item.option1}
						</li>
						<li className={item.selectedOption && (item.selectedOption === ("option" + `${2}`)) ? "selected" : ""} value={item.question_id} onClick={(e) => handleSelectOption(e, 2)}>
							{/* <RenderHtml htmlString={item.option2} />*/}{item.option1}
						</li>
						<li className={item.selectedOption && (item.selectedOption === ("option" + `${3}`)) ? "selected" : ""} value={item.question_id} onClick={(e) => handleSelectOption(e, 3)}>
							<RenderHtml htmlString={item.option3} />
						</li>
						<li className={item.selectedOption && (item.selectedOption === ("option" + `${4}`)) ? "selected" : ""} value={item.question_id} onClick={(e) => handleSelectOption(e, 4)}>
							<RenderHtml htmlString={item.option4} />
						</li>
					</ul>
				</div>
			</TabPanel>
		)
	})
	const handleNextButton = () => {
		console.log(tabIndex);
		if (tabIndex < QuestionPaper.length - 1) {
			setTabIndex(tabIndex + 1);
		}
	}

	const handlePrevButton = () => {
		if (tabIndex > 0) {
			setTabIndex(tabIndex - 1);
		}
	}

	const handleMarkButton = () => {
		// console.log(tabIndex);
		let tempQuestion = QuestionPaper.map((item) => {
			if (item.question_id === (tabIndex + 1) && item.markedQues) {
				return { ...item, markedQues: false }
			}
			return item.question_id === (tabIndex + 1) ? { ...item, markedQues: true } : item
		});
		setQuestionPaper(tempQuestion);
		// let tempQuestion = QuestionPaper.map((item) =>
		//     item.questionsId === (tabIndex + 1) ? { ...item, markedQues: true } : item
		// )
		// setQuestionPaper(tempQuestion);
	}

	const numberOfQuestion = QuestionPaper.map((item, indexes) => {
		return (
			<Tab key={indexes}><span className={(item.markedQues && item.selectedOption ? "mark-selected" : "") || (item.markedQues ? "markedQ" : "") || (item.selectedOption ? "selected" : "")}>{indexes + 1}</span></Tab>
		)
	})

	const handleSubmitButton = () => {
		// Navigate('/submit?isSubmit=successfullySubmitted');
		console.log(QuestionPaper);
		if (!areyoursuretoSubmitpopup) {
			setAreYouSureToSubmitPopup(true);
			return;
		}
		setAreYouSureToSubmitPopup(false);
		setShowOpen(true);

	}

	const HandlePreview = () => {
		setShowOpen(false);
		setModelClose(true);
		Navigate('/preview?testId=test1')
	}
	window.addEventListener("beforeunload", (event) => {
		event.preventDefault();
		event.returnValue = "Are you sure you want to leave?";
	  });

	return (
		<>

			<div className="mcq-bnr-sec">
				<div className="mcq-cont">
					<h2>MCQ</h2>
				</div>
				<div className="bread-crb"><ul><li><a href="/">Home</a></li><span><i className="flaticon-next"></i></span><li><a href="/mcq">mcq</a></li></ul></div>
			</div>


			<div className="mcq-section">

				<div className="time-right">
					<div className="timer-txt">
						<img src={clockimg} alt="" /> Remaining Time({formatTime(time)})
					</div>
				</div>

				<div className="mcq-form">
					<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-8">
									{questionPaperList}
								</div>
								<div className="col-md-4">
									<div className="session-cont">
										<h2>Session Summary</h2>
										<TabList>
											{numberOfQuestion}
										</TabList>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-3 col-ms-12">
									<div className="prev-btn">
										<button onClick={handlePrevButton}>Previous</button>
									</div>
								</div>
								<div className="col-md-5 col-sm-12">
									<div className="next-btn">

										<button onClick={handleMarkButton}>Mark</button>
										<button onClick={handleNextButton}>Next</button>
										<button onClick={handleSubmitButton}>Submit</button>
									</div>
								</div>

							</div>
						</div>
					</Tabs>
				</div>

				{/* Time is Over */}
				<Modal data-bs-backdrop="static" show={showOpen} className="thnku_pop">
					<Modal.Header >
						<Modal.Title>Thank You!</Modal.Title>
					</Modal.Header>
					<Modal.Body>Submitted</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={closeModal}>
							Home
						</Button>
						<Button variant="info" onClick={HandlePreview}>
							preview Answer
						</Button>
					</Modal.Footer>
				</Modal>

				{/* Are Your Sure to Submit Test Before time */}
				<Modal data-bs-backdrop="static" show={areyoursuretoSubmitpopup} className="thnku_pop">
					<Modal.Header >
						{/* <Modal.Title>Thanku You!</Modal.Title> */}
					</Modal.Header>
					<Modal.Body>Are you Sure to submit the exam ?</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={() => setAreYouSureToSubmitPopup(false)}>
							close
						</Button>
						<Button variant="light" onClick={handleSubmitButton}>
							yes
						</Button>
					</Modal.Footer>
				</Modal>
			</div>

		</>
	)
}


export default QuestionList;