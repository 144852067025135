import React, { useEffect, useState } from "react";
import './gemcp.css';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import gemcpimg from '../../assets/img/gem-cp-img.jpg';
import RenderHtml from '../common/htmlRender';


import 'react-tabs/style/react-tabs.css';

import Axios from '../../services/AxiosInstance';
import Loader from "../common/Loader";
const GemCp = () => {
    const [searchParams] = useSearchParams();
    const [tabIndex, setTabIndex] = useState(0);
    const [importantLinks, setImportantLinks] = useState([]);
    const [importantLinkHeading, setImportantLinkHeading] = useState('');
    const [importantLinkContent, setImortantLinkContent] = useState('');
    const [isLoading, setIsLoading] = useState({ isImportantLinkLoader: false, isLinkContentLoader: false });

    const Navigate = useNavigate();

    const getAllImportantLink = () => {
        Axios.get(`api/assocham/all-important-links?menu_id=${searchParams.get('menu_id')}&sub_menu_id=${searchParams.get('sub_menu_id')}`, setIsLoading({ ...isLoading, isImportantLinkLoader: true }))
            .then((res) => {
                if (res.status === 200) {
                    setImportantLinks(res.data.data);
                    handleImportantContent(res.data.data[0].content_menu_id);
                    setImportantLinkHeading(res.data.data[0].content_menu_name);
                    setIsLoading({ ...isLoading, isImportantLinkLoader: false });
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading({ ...isLoading, isImportantLinkLoader: false });
            })
    }

    const showImportLinkData = importantLinks.map((link, index) => {
        return (

            <li key={index} onClick={() => handleImportantContent(link.content_menu_id)} className={importantLinkHeading == link.content_menu_name ? 'Active' : ''}>{link.content_menu_name}</li>

        )
    })

    const handleImportantContent = (important_link_id) => {
        for (let i = 0; i < importantLinks.length; i++) {
            if (important_link_id == importantLinks[i].content_menu_id) {
                setImportantLinkHeading(importantLinks[i].content_menu_name);
            }
        }
        Axios.get(`api/assocham/get-important-links-data?content_menu_id=${important_link_id}`, setIsLoading({ ...isLoading, isLinkContentLoader: true }))
            .then((res) => {
                console.log("data", res);
                if (res.status === 200) {
                    setImortantLinkContent(res.data.data[0].content);
                    setIsLoading({ ...isLoading, isLinkContentLoader: false });
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading({ ...isLoading, isLinkContentLoader: false });
            })
    }

    useEffect(() => {
        if (!searchParams.get('menu_id')) {
            Navigate('/');
        }
        getAllImportantLink();
    }, [])
    return (
        <>
            <div className="abt-bnr-sec">
                <div className="abt-hding">
                    <h2>GEM CP</h2>
                </div>
                <div className="bread-crb">
                    <ul>
                        <li><Link to=''>Home</Link></li><span><i className="flaticon-next"></i></span>
                        <li><Link to=''>GEM CP</Link></li>
                    </ul>
                </div>
            </div>

            <div className="abt-content-sec">
                <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="important_Links">
                                    <h2>Important Links</h2>
                                    <ul>
                                        {isLoading.isImportantLinkLoader ? <Loader /> : ""}
                                        {showImportLinkData}
                                    </ul>
                                    {/* <TabList>
                                        <Tab>GEM CP</Tab>
                                        <Tab>Eligibility</Tab>
                                        <Tab>Examination Fee</Tab>
                                        <Tab>Location & Frequency  of Exam</Tab>
                                        <Tab>Structure & Content</Tab>
                                    </TabList> */}

                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="right-cont">
                                    <h2>{importantLinkHeading}</h2>
                                </div>
                                <div className="right-txt"><RenderHtml htmlString={importantLinkContent} /> {isLoading.isLinkContentLoader ? <Loader /> : ""}</div>
                                {/* <TabPanel>

                                    <div className="right-cont">
                                        <h2>GEM CP</h2>
                                    </div>
                                    <div className="row">

                                        <div className="col-lg-6 col-md-12">
                                            <div className="gem-cp-right-txt">
                                                <h4>1500+ GEM Certified
                                                    Professionals so far and
                                                    increasing….</h4>
                                                <p>ASSOCHAM, the century old
                                                    organization is recognizing the
                                                    professionals, faculties and students
                                                    as GEM Certified Professional (GEM
                                                    CP) once they clear the examination. It
                                                    paves the way for a wide range of
                                                    collaborative endeavors.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="right-txt-img">
                                                <img src={gemcpimg} alt="" />
                                            </div>
                                        </div>
                                    </div>

                                </TabPanel>

                                <TabPanel>
                                    <div className="right-cont">
                                        <h2>Eligibility</h2>
                                    </div>
                                    <div className="right-txt">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    </div>

                                </TabPanel>

                                <TabPanel>
                                    <div className="right-cont">
                                        <h2>Examination Fee</h2>
                                    </div>
                                    <div className="right-txt">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                    <div className="right-cont">
                                        <h2>Location & Frequency  of Exam</h2>
                                    </div>
                                    <div className="right-txt">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                    <div className="right-cont">
                                        <h2>Structure & Content</h2>
                                    </div>
                                    <div className="right-txt">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
                                    </div>
                                </TabPanel> */}

                            </div>
                        </div>
                    </div>
                </Tabs>
            </div>
        </>
    )
}


export default GemCp;